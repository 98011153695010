import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as importGlebeFormAction from './import-glebe-form.actions';
import { ApplicationService } from '../../../services/application.service';
import { Config, GenericFirestoreService } from '../../../services/generic-firestore.service';
import { GenericHttpService } from '../../../services/generic-http.service';
import { MyToastrService } from '../../../services/toastr.service';
import { TranslateTypes } from '../../../services/translation.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { Glebe, Harvest } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';

@Injectable()
export class ImportGlebeFormEffects {
  constructor(
    private actions$: Actions,
    private toastrService: MyToastrService,
    private genericFirestoreService: GenericFirestoreService,
    private genericHttpServiceService: GenericHttpService,
    private applicationService: ApplicationService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para listar todas as glebas colhidas.
   */
  public getHarvest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importGlebeFormAction.GET_HARVESTS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Harvest>(`${this.applicationService.getFarmRefDatabase()}/harvests`)
          .pipe(
            map((harvests: Array<Harvest>) =>
              importGlebeFormAction.GET_HARVESTS_SUCCESS({
                harvests: harvests.filter(
                  (harvest: Harvest) => harvest.id !== this.applicationService.getHarvestId()
                ),
              })
            ),
            catchError((error) => of(importGlebeFormAction.GET_HARVESTS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar todas as glebas colhidas.
   */
  public getGlebes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(importGlebeFormAction.GET_GLEBES),
      switchMap(() => {
        return this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getFarmRefDatabase()}/glebesFarm`, {
            propertyDate: [['finalHarvestDate']],
          } as Config)
          .pipe(
            map((glebes: Array<Glebe>) =>
              importGlebeFormAction.GET_GLEBES_SUCCESS({
                glebes: glebes.filter(
                  (harvest: Glebe) => harvest.id !== this.applicationService.getHarvestId()
                ),
              })
            ),
            catchError((error) => of(importGlebeFormAction.GET_GLEBES_FAIL(error)))
          );
      })
    );
  });

  /**
   * Este método chama um serviço para cadastrar gleba.
   */
  public createGlebe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importGlebeFormAction.CREATE_IMPORT_GLEBE),
      mergeMap((action) => {
        const parameters = {
          type: 'glebes',
          customerId: this.applicationService.getCustomerId(),
          farmId: this.applicationService.getFarmId(),
          harvestId: this.applicationService.getHarvestId(),
        };

        return this.genericHttpServiceService
          .create<{ ids: Array<string> }>('importToHarvest', { ...parameters, ids: action.ids })
          .toPromise()
          .then(() => {
            this.toastrService.success(
              this.labelPipe.transform(this.trans.services.create.importGlebeSuccess)
            );
            return importGlebeFormAction.CREATE_IMPORT_GLEBE_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return importGlebeFormAction.CREATE_IMPORT_GLEBE_FAIL(error);
          });
      })
    )
  );
}
