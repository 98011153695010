<!-- Loading -->
<app-loading
  [isOpen]="state.loading || state.loadingEvolutionChartByFields"
  [excitedTime]="'1s'"
></app-loading>

<div class="container" fxLayout="column" (window:resize)="onResize()">
  <div class="toolbar-container" fxLayout="column">
    <mat-toolbar class="mat-toolbar">
      <mat-toolbar-row class="material-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <h2 class="title">
            {{ trans.title.interactiveReport | translate }}{{ createReportTitle() }}
          </h2>
          <span class="date">{{ state.startDate }} - {{ state.endDate }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
          <ng-container *ngIf="applicationService.getCustomer()?.logo; else elseTemplate">
            <img
              class="logo"
              [src]="'data:image/png;base64,' + applicationService.getCustomer()?.logo"
              alt="logo"
            />
          </ng-container>
          <ng-template #elseTemplate>
            <img class="logo" src="./../../../assets/images/logo_v_mono5.png" alt="logo" />
          </ng-template>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-caption">
      <div fxLayout="column">
        <div class="caption-title" fxFlex>{{ trans.title.captions | translate }}</div>
        <div class="caption-colum-2" fxLayout="row" fxFlex>
          <div class="caption-pheromone" fxLayout="column" fxLayoutGap="5px" fxFlex>
            <div class="pheromone-title">{{ trans.title.pheromoneCaption | translate }}</div>
            <div
              class="occurrence-caption"
              fxLayout="row"
              fxLayoutAlign="start start"
              *ngFor="let occurrence of getOccurrences()"
            >
              <img
                [src]="getIconPheromoneSvg(occurrence.name).url"
                style="height: 16px; width: 16px"
              />
              <div class="text-pheromone">{{ occurrence.name }}</div>
            </div>
            <div
              class="occurrence-caption"
              fxLayout="row"
              fxLayoutAlign="start start"
              *ngIf="getOccurrences().length > 0"
            >
              <img
                [src]="getIconPheromoneSvg('NO_COMMUNICATION').url"
                style="height: 16px; width: 16px"
              />
              <div class="text-pheromone">{{ trans.label.trapNoCommunication | translate }}</div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="10px" fxFlex>
            <div class="heatmap-title" fxFlex>{{ trans.title.heatMap | translate }}</div>
            <div fxLayout="row">
              <div class="heatmap">
                <div class="heatmap-red"></div>
                <div class="heatmap-yellow"></div>
                <div class="heatmap-green"></div>
                <div class="heatmap-white"></div>
              </div>
              <div class="caption-infestation" fxLayout="column" fxLayoutGap="3px">
                <div class="text-pheromone">{{ trans.label.highInfestation | translate }}</div>
                <div class="text-pheromone">{{ trans.label.mediumInfestation | translate }}</div>
                <div class="text-pheromone">{{ trans.label.lowInfestation | translate }}</div>
                <div class="text-pheromone">{{ trans.label.noMonitoring | translate }}</div>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="10%" fxFlex>
            <div style="background: white; width: 1px"></div>
            <div fxFlex="53" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="7px">
              <button class="button-caption" (click)="openLinkWhats()">
                <div class="whats-icon"></div>
                <div class="label">CONTATO</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button fxFlex class="collapsible" (click)="openCaption($event)">
      <i
        class="las la-angle-down"
        *ngIf="!this.stateCaption"
        style="font-size: 16px; color: white"
      ></i>
      <i
        class="las la-angle-up"
        *ngIf="this.stateCaption"
        style="font-size: 16px; color: white"
      ></i>
    </button>
  </div>
  <div fxLayout="column" fxLayout.gt-md="row-reverse">
    <mat-card
      class="card-Map"
      fxLayout="column"
      fxFlex.gt-md="65"
      *ngIf="!this.noOccurrence && !state.loading"
    >
      <!-- Google maps - heatmap -->
      <agm-map
        fxFlex
        *ngIf="initMap && !this.noOccurrence"
        #AgmMap
        [latitude]="-21.31370884151141"
        [longitude]="-45.93946863345604"
        [zoom]="20"
        [maxZoom]="20"
        [minZoom]="8"
        (zoomChange)="onZoomChange($event)"
        [gestureHandling]="'greedy'"
        [mapTypeId]="mapTypeId"
        [ngStyle]="{ height: expandView || !isMobile ? '90vh' : '50vh' }"
        [scrollwheel]="true"
        [disableDoubleClickZoom]="true"
        [mapDraggable]="true"
        [disableDefaultUI]="true"
        [backgroundColor]="'transparent'"
        (mapReady)="mapReady($event)"
      >
        <div *ngFor="let field of this.state.fields">
          <!-- Polygon ( zIndex 1 ) -->

          <agm-polygon
            [fillOpacity]="0.1"
            [zIndex]="1"
            [fillColor]="'#ffffff'"
            [strokeWeight]="3"
            [strokeColor]="
              monitoringReportSelected?.field?.fieldId === field.id ? '#004762' : '#fafafa'
            "
            [geodesic]="true"
            [paths]="getPolygonPoints(field.id)"
            (polyClick)="onFieldSelect(field.id, field.glebe.id)"
          ></agm-polygon>
        </div>

        <div *ngFor="let map of getMonitoringReport()">
          <!-- Marker ( zIndex 3 ) -->

          <agm-marker
            *ngFor="let point of map.points; let i = index"
            [latitude]="point.lat"
            [longitude]="point.lng"
            [zIndex]="3"
            [iconUrl]="point.icon"
          >
            <agm-snazzy-info-window
              *ngIf="point.name || point.name"
              [isOpen]="openTagPoint"
              [closeOnMapClick]="false"
              [closeWhenOthersOpen]="false"
              [openOnMarkerClick]="false"
              [panOnOpen]="false"
              [showCloseButton]="false"
              [placement]="'right'"
              wrapperClass="material-marker-name"
            >
              <ng-template>
                {{ point.name }}
              </ng-template>
            </agm-snazzy-info-window>
          </agm-marker>
          <agm-marker
            [latitude]="map.centroid.lat"
            [longitude]="map.centroid.lng"
            [zIndex]="3"
            [iconUrl]="getIconOccurrenceSvg(map.occurrenceId)"
            *ngIf="map.centroid"
          >
            <agm-snazzy-info-window
              *ngIf="map.field"
              [isOpen]="openTagPoint"
              [closeOnMapClick]="false"
              [closeWhenOthersOpen]="false"
              [openOnMarkerClick]="false"
              [panOnOpen]="false"
              [showCloseButton]="false"
              [placement]="'right'"
              wrapperClass="material-marker-name"
            >
              <ng-template>
                {{ fieldName(map.field.fieldId) }}
              </ng-template>
            </agm-snazzy-info-window>
          </agm-marker>
        </div>
      </agm-map>
      <div class="material-container-absolute-button-right" *ngIf="isMobile">
        <div fxLayout="column" fxLayoutAlign="space-between end">
          <button mat-icon-button (click)="changeView()">
            <i
              class="las la-expand-arrows-alt"
              style="font-size: 28px; color: white"
              *ngIf="!expandView"
            ></i>
            <i
              class="las la-compress-arrows-alt"
              style="font-size: 28px; color: white"
              *ngIf="expandView"
            ></i>
          </button>
        </div>
      </div>
      <div class="material-container-absolute-button-right" *ngIf="!isMobile">
        <div fxLayout="column" fxLayoutAlign="space-between end">
          <button
            mat-raised-button
            class="button-back-farm"
            fxLayout="row"
            (click)="tabSelected($event)"
          >
            <i
              fxFlex="auto"
              class="las"
              [class]="'la-home'"
              [ngStyle]="{ fontSize: '32px', color: 'white' }"
            ></i>
            <div fxFlex="auto" class="text">{{ trans.button.backFarm | label }}</div>
          </button>
        </div>
      </div>
    </mat-card>
    <div
      class="tabs"
      [style.display]="isMobile && !this.noOccurrence && !state.loading && !expandView ? 'block' : 'none'"
    >
      <button class="farm tablinks" (click)="tabSelected($event)">
        <i class="las" [class]="'la-home'" id="defaultOpen" [ngStyle]="{ fontSize: '24px' }"></i>
        <div class="content">{{ titleFarm }}</div>
      </button>
      <button
        class="field tablinks"
        [id]="obj.glebe.id"
        *ngFor="let obj of fieldsByGlebe"
        (click)="tabSelected($event, obj)"
      >
        <div class="content-field" *ngIf="obj.fields.length > 1 && obj.glebe.alias">
          {{ obj.glebe.alias }}
        </div>
        <div class="content-field" *ngIf="obj.fields.length > 1 && !obj.glebe.alias">
          {{ obj.glebe.name }}
        </div>
        <!-- <div class="content-field" *ngIf="obj.fields.length <= 1 && obj.fields[0].alias">
          {{ obj.fields[0].alias }}
        </div> -->
        <div class="content-field" *ngIf="obj.fields.length <= 1">
          {{ obj.fields[0].name }}
        </div>
      </button>
    </div>
    <!-- Filtro de ocorrências -->
    <div fxLayout="row" fxLayoutAlign="center start">
      <div
        fxFlex="auto"
        fxLayout="row"
        fxLayoutGap="5px"
        class="filter-occurrences"
        style="margin: 20px 20px 0px; position: fixed; height: 70px"
        *ngIf="noOccurrence && isMobile"
      >
        <button
          class="button-occurrence filterlinks"
          fxFlex
          *ngFor="let occurrence of getOccurrences()"
          (click)="onSelectionChangeOccurrence(occurrence)"
          [ngStyle]="
            occurrence.id === occurrenceSelected.id
              ? { background: 'white' }
              : { background: '#9F9B9B' }
          "
        >
          <img class="icon" [src]="getIconOccurrence(occurrence.id)" />
          <div
            class="content"
            [ngStyle]="
              occurrence.id === occurrenceSelected.id ? { color: '#505050' } : { color: '#737272' }
            "
          >
            {{ occurrence.name }}
          </div>
        </button>
      </div>
    </div>
    <div *ngIf="noOccurrence && !state.loading" style="width: 100%; height: 100vh">
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
        <div style="font-size: 1.6em; font-weight: 500; margin: 0 5%">
          {{ trans.title.noOccurrencesReport | translate }}
        </div>
      </div>
    </div>
    <div
      fxFlex
      class="column"
      fxLayout="column"
      fxFlex.gt-md="35"
      *ngIf="!expandView && !state.loading"
    >
      <div fxLayout="column" fxLayoutAlign="start center">
        <!-- Filtro de ocorrências -->
        <div
          fxFlex="auto"
          fxLayout="row"
          fxLayoutGap="5px"
          class="filter-occurrences"
          *ngIf="!noOccurrence || !isMobile"
        >
          <button
            class="button-occurrence filterlinks"
            fxFlex
            *ngFor="let occurrence of getOccurrences()"
            (click)="onSelectionChangeOccurrence(occurrence)"
            [ngStyle]="
              occurrence.id === occurrenceSelected.id
                ? { background: 'white' }
                : { background: '#9F9B9B' }
            "
          >
            <img class="icon" [src]="getIconOccurrence(occurrence.id)" />
            <div
              class="content"
              [ngStyle]="
                occurrence.id === occurrenceSelected.id
                  ? { color: '#505050' }
                  : { color: '#737272' }
              "
            >
              {{ occurrence.name }}
            </div>
          </button>
        </div>
        <div
          fxFlex
          *ngIf="!this.noOccurrence"
          style="
            width: auto;
            height: 100%;
            margin: 10px 30px;
            text-align: center;
            color: white;
            font-size: 14px;
            font-family: Roboto;
            font-weight: 200;
            word-wrap: break-word;
          "
        >
          Para monitorar a ocorrência específica de um atrativo, clique no botão da ocorrência
          desejada.
        </div>
        <!-- Evolution chart -->
        <div
          class="mat-elevation-z8 card-chart"
          *ngIf="!this.noOccurrence && getEvolutionChartData()"
        >
          <div style="padding: 15px 15px 0" class="chart-title">
            <mat-card-title>{{
              (trans.title.evolutionOfSurveys | translate) + titleChart
            }}</mat-card-title>
          </div>
          <mat-card-content class="card-content-chart">
            <ng-container *ngIf="getEvolutionChartData()">
              <app-evolution-chart [data]="getEvolutionChartData()" [isFruit]="false"></app-evolution-chart>
            </ng-container>
          </mat-card-content>
        </div>

        <!-- reportAccumulated chart -->
        <!-- <div class="mat-elevation-z8 card-chart" *ngIf="this.fieldSelected">
          <div style="padding: 15px 15px 0" class="chart-title">
            <mat-card-title>{{ trans.title.accumulatedChart | translate }}</mat-card-title>
          </div>
          <mat-card-content class="card-content-chart">
            <ng-container *ngIf="getReportAccumulatedData()">
              <app-accumulated-chart [data]="getReportAccumulatedData()"></app-accumulated-chart>
            </ng-container>
          </mat-card-content>
        </div> -->

        <!-- Mad chart -->
        <!-- <div
          class="mat-elevation-z8 card-chart"
          *ngIf="
            !this.noOccurrence &&
            ['CAPITATA', 'ANASTREPHA'].includes(this.occurrenceSelected.id) &&
            this.fieldSelected &&
            this.state.madChart.length > 0
          "
        >
          <div style="padding: 15px 15px 0" class="chart-title">
            <mat-card-title>{{ trans.title.chartMad | translate }}</mat-card-title>
          </div>
          <mat-card-content class="card-content-chart">
            <ng-container *ngIf="this.state.madChart.length > 0">
              <app-mad-chart [data]="this.state.madChart"></app-mad-chart>
            </ng-container>
          </mat-card-content>
        </div> -->

        <!-- Card Farm/Fields info -->
        <div fxLayout="row wrap" fxLayoutAlign="center start">
          <div class="mat-elevation-z8 card" *ngIf="cardFarm && !this.noOccurrence" fxFlex>
            <div style="padding: 15px">
              <mat-card-title>{{ cardFarm.title }}</mat-card-title>
            </div>
            <mat-card-content class="card-container">
              <div class="card-container" fxLayout="row">
                <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                  <img
                    style="height: 80px; width: 80px; text-align: center"
                    [src]="cardFarm.image"
                  />
                </div>
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
                  <div class="title-field">{{ cardFarm.subTitle }}</div>
                  <div class="card-line" *ngFor="let line of cardFarm.field">
                    <span class="card-label">{{ line.label }}:</span
                    ><span class="card-content">{{ line.content }}</span>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </div>
          <!-- Card Trap Info -->
          <div *ngIf="!this.noOccurrence">
            <div fxLayout="row wrap" fxLayoutAlign="center start">
              <div class="mat-elevation-z8 card" *ngFor="let trap of cardTrap" fxFlex>
                <div style="padding: 15px">
                  <mat-card-title>{{ trap.title }}</mat-card-title>
                </div>
                <mat-card-content class="card-container">
                  <div class="card-container" fxLayout="row">
                    <div fxFlex="40" fxLayout="row" fxLayoutAlign="center center">
                      <img
                        style="height: 80px; width: 80px; text-align: center"
                        [src]="trap.image"
                      />
                    </div>
                    <div
                      fxFlex="60"
                      fxLayout="column"
                      fxLayoutAlign="center start"
                      fxLayoutGap="5px"
                    >
                      <div class="title-field">{{ trap.subTitle }}</div>
                      <div class="card-line">
                        <span class="card-trap-text">{{ trap.pheromoneText }} </span>
                        <span class="card-trap-text bold">{{ trap.pheromone }}</span>
                      </div>
                      <div fxLayout="row">
                        <div fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
                          <img
                            style="height: 24px; width: 24px; text-align: center"
                            [src]="trap.icon"
                          />
                        </div>
                        <div fxFlex="70" class="card-trap-pheromone">{{ trap.iconText }}</div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
