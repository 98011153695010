import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as trapReportAction from './trap-report.actions';
import { ApplicationService } from '../../services/application.service';
import { GenericFirestoreService } from '../../services/generic-firestore.service';
import { GenericReportFilesService } from '../../services/generic-report-files.service';
import { MyToastrService } from '../../services/toastr.service';
import { TranslateTypes } from '../../services/translation.service';
import { Field, TrapReport, Glebe, Trap } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { trapReportColumns } from './trap-reports.state';
import { TrapReportPath } from 'src/app/constants/trapReportPath';
import { RemoveReport } from 'src/app/interfaces/RemoveReport';
import { ReportTypes } from '@tarvos-ag/tarvos-firestore-models/src/enums/ReportTypes';

@Injectable()
export class TrapReportEffects {
  constructor(
    private actions$: Actions,
    private genericReportFilesService: GenericReportFilesService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private toastrService: MyToastrService,
    public trans: TranslateTypes
  ) {}

  /**
   * Este método chama um serviço para buscar um relatório.
   */
  public getTrapReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportAction.GET_TRAP_REPORTS),
      switchMap(() =>
        this.genericFirestoreService
          .getCombine<TrapReport>([
            {
              ref: `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.XLS}`,
            },
            {
              ref: `${this.applicationService.getHarvestRefDatabase()}/${
                TrapReportPath.INTERACTIVE
              }`,
            },
            {
              ref: `${this.applicationService.getHarvestRefDatabase()}/${
                TrapReportPath.INTERACTIVEFRUIT
              }`,
            },
            // {
            //   ref: `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.GEOREFERENCED}`,
            // },
            {
              ref: `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.MAD}`,
            },
          ])
          .pipe(
            map(
              (trapReports: Array<TrapReport>) => {
                const filteredTrapReports = trapReports.filter(
                  (report) => report.track?.visible !== false
                );
                return trapReportAction.GET_TRAP_REPORTS_SUCCESS({
                  trapReports: filteredTrapReports,
                });
              },
              catchError((error) => of(trapReportAction.GET_TRAP_REPORTS_FAIL(error)))
            )
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar talhões.
   */
  public getFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportAction.GET_FIELDS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Field>(`${this.applicationService.getHarvestRefDatabase()}/fields`)
          .pipe(
            map((fields: Array<Field>) => trapReportAction.GET_FIELDS_SUCCESS({ fields })),
            catchError((error) => of(trapReportAction.GET_FIELDS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para listar glebas.
   */
  public getGlebes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportAction.GET_GLEBES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Glebe>(`${this.applicationService.getHarvestRefDatabase()}/glebes`)
          .pipe(
            map((glebes: Array<Glebe>) => trapReportAction.GET_GLEBES_SUCCESS({ glebes })),
            catchError((error) => of(trapReportAction.GET_GLEBES_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para armadilhas.
   */
  public getTraps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportAction.GET_TRAPS),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<Trap>(`${this.applicationService.getFarmRefDatabase()}/traps`, {
            queryFn: (ref) =>
              // Don't know why typing doesn't work here
              // queryFn: (ref: CollectionReference) =>
              ref.where('harvestId', '==', this.applicationService.getHarvestId()),
          })
          .pipe(
            map((traps: Array<Trap>) => trapReportAction.GET_TRAPS_SUCCESS({ traps })),
            catchError((error) => of(trapReportAction.GET_TRAPS_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para ocorrências.
   */
  public getOccurrences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportAction.GET_OCCURRENCES),
      switchMap(() =>
        this.genericFirestoreService
          .getAll<any>(`${this.applicationService.getHarvestRefDatabase()}/filters`, {
            // Don't know why typing doesn't work here
            // queryFn: (ref: CollectionReference) => ref.orderBy('name', 'asc'),
            queryFn: (ref) => ref.orderBy('name', 'asc'),
          })
          .pipe(
            map((occurrences: any) =>
              trapReportAction.GET_OCCURRENCES_SUCCESS({
                occurrences,
              })
            ),
            catchError((error) => of(trapReportAction.GET_OCCURRENCES_FAIL(error)))
          )
      )
    );
  });

  /**
   * Este método chama um serviço para exportar um relatório em xls
   */
  public createReportXls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trapReportAction.CREATE_REPORT_XLS),
      switchMap((action) =>
        this.genericReportFilesService
          .generateExcelFile(action.trapReportXls, trapReportColumns)
          .then(() => trapReportAction.CREATE_REPORT_XLS_SUCCESS())
          .catch((error) => trapReportAction.CREATE_REPORT_XLS_FAIL(error))
      )
    );
  });

  /**
   * Este método chama um serviço para remover relatório de armadilhas.
   */
  public removeTrapReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapReportAction.REMOVE_TRAP_REPORTS),
      switchMap(async (action) => {
        const pdfInteractiveIds = action.reports
          .filter((x: any) => {
            return (
              (x.type === 'PDF' && x.reportFormat === ReportTypes.INTERACTIVE) ||
              (x.reportFormat === undefined && x.type === 'PDF')
            );
          })
          .map((x: any) => x.id);
        const pdfInteractiveFruitIds = action.reports
          .filter((x: any) => {
            return (
              (x.type === 'PDF' && x.reportFormat === ReportTypes.INTERACTIVEFRUIT) ||
              (x.reportFormat === undefined && x.type === 'PDF')
            );
          })
          .map((x: any) => x.id);
        const pdfGeoreferencedIds = action.reports
          .filter((x: any) => {
            if (x.reportFormat) {
              return x.type === 'PDF' && x.reportFormat === ReportTypes.GEOREFERENCED;
            }
            return false;
          })
          .map((x: any) => x.id);

        const pdfMadIds = action.reports
          .filter((x: any) => {
            if (x.reportFormat) {
              return x.type === 'PDF' && x.reportFormat === ReportTypes.MAD;
            }
            return false;
          })
          .map((x: any) => x.id);

        const xlsIds = action.reports
          .filter((x: any) => x.type === ReportTypes.XLS)
          .map((x: any) => x.id);

        await this.genericFirestoreService
          .batchRemove<any>(
            `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.INTERACTIVE}`,
            pdfInteractiveIds
          )
          .then(() => {
            pdfInteractiveIds.forEach(() =>
              this.toastrService.success(this.trans.services.remove.reportSuccess)
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_FAIL(error);
          });

        await this.genericFirestoreService
          .batchRemove<any>(
            `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.INTERACTIVEFRUIT}`,
            pdfInteractiveFruitIds
          )
          .then(() => {
            pdfInteractiveFruitIds.forEach(() =>
              this.toastrService.success(this.trans.services.remove.reportSuccess)
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_FAIL(error);
          });
        await this.genericFirestoreService
          .batchRemove<any>(
            `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.GEOREFERENCED}`,
            pdfGeoreferencedIds
          )
          .then(() => {
            pdfGeoreferencedIds.forEach(() =>
              this.toastrService.success(this.trans.services.remove.reportSuccess)
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_FAIL(error);
          });

        await this.genericFirestoreService
          .batchRemove<any>(
            `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.MAD}`,
            pdfMadIds
          )
          .then(() => {
            pdfMadIds.forEach(() =>
              this.toastrService.success(this.trans.services.remove.reportSuccess)
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_FAIL(error);
          });

        return this.genericFirestoreService
          .batchRemove<any>(
            `${this.applicationService.getHarvestRefDatabase()}/${TrapReportPath.XLS}`,
            xlsIds
          )
          .then(() => {
            xlsIds.forEach(() =>
              this.toastrService.success(this.trans.services.remove.reportSuccess)
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_SUCCESS();
          })
          .catch((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            return trapReportAction.REMOVE_TRAP_REPORTS_FAIL(error);
          });
      })
    )
  );

  /**
   * Este método chama um serviço para remover talhões.
   */
  public removeTrapReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trapReportAction.ARCHIVE_TRAP_REPORT),
      mergeMap((action) => {
        const report: RemoveReport = action.reports[0];
        const { id, type, reportFormat } = report;

        if (
          type === 'PDF' &&
          [ReportTypes.INTERACTIVE, ReportTypes.INTERACTIVEFRUIT].includes(reportFormat)
        ) {
          const path = TrapReportPath[reportFormat];
          return this.genericFirestoreService
            .update<TrapReport>(`${this.applicationService.getHarvestRefDatabase()}/${path}`, {
              id,
              track: {
                visible: false,
              },
              metadata: {
                archivedAt: new Date(),
              },
              status: 'COMPLETED',
            })
            .then(() => {
              this.toastrService.success(this.trans.services.remove.reportSuccess);
              return trapReportAction.ARCHIVE_TRAP_REPORT_SUCCESS();
            })
            .catch((error) => {
              this.toastrService.error(
                `${
                  error.code !== undefined
                    ? error.code
                    : error.error.code !== undefined
                    ? error.error.code
                    : error.error.internalCode
                }`
              );
              return trapReportAction.ARCHIVE_TRAP_REPORT_FAIL(error);
            });
        } else {
          let path;
          if (type === 'PDF' && reportFormat === 'GEOREFERENCED')
            path = TrapReportPath.GEOREFERENCED;
          else if (type === 'PDF' && reportFormat === 'MAD') path = TrapReportPath.MAD;
          else if (type === 'XLS') path = TrapReportPath.XLS;

          const docRef = `${this.applicationService.getHarvestRefDatabase()}/${path}/${id}`;
          return this.genericFirestoreService
            .remove<any>(docRef)
            .then(() => {
              this.toastrService.success(this.trans.services.remove.reportSuccess);
              return trapReportAction.ARCHIVE_TRAP_REPORT_SUCCESS();
            })
            .catch((error) => {
              this.toastrService.error(
                `${
                  error.code !== undefined
                    ? error.code
                    : error.error.code !== undefined
                    ? error.error.code
                    : error.error.internalCode
                }`
              );
              return trapReportAction.ARCHIVE_TRAP_REPORT_FAIL(error);
            });
        }
      })
    )
  );
}
