import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../environments/environment';
import { ImportKmzState } from './view/import-kmz/import-kmz.state';

/**
 * REDUCER
 */
import * as customerReducer from './view/customer/create-customer/create-customer.reducer';
import * as farm from './view/farm/farm.reducer';
import * as gmTabs from './view/gm-tabs/gm-tabs.reducer';
import * as gm from './view/gm/gm.reducer';
import * as headerReducer from './view/header/header.reducer';
import * as loginReducer from './view/login/login.reducer';
import * as trap from './view/trap/trap.reducer';
import * as user from './view/user/user.reducer';
import * as routeOptimizationReducer from './view/route-optimization/route-optimization.reducer';
import * as routesReducer from './view/route-optimization/store/routes/route-route-optimization.reducer';
import * as customerRoutesOptimizationReducer from './view/route-optimization/store/customers/customers-route-optimization.reducer';
import * as farmsRoutesOptimizationReducer from './view/route-optimization/store/farms/farms-route-optimization.reducer';

import * as occurrenceSettingsReducer from './view/configuration/occurrence-settings/occurrence-settings.reducer';
import * as systemSettingsReducer from './view/configuration/system-settings/system-settings.reducer';
import * as fieldFormReducer from './view/field/field-form/field-form.reducer';
import * as fieldReducer from './view/field/field.reducer';
import * as importFieldFormReducer from './view/field/import-field-form/import-field-form.reducer';
import * as reapFormReducer from './view/field/reap-form/reap-form.reducer';
import * as glebeFormReducer from './view/glebe/glebe-form/glebe-form.reducer';
import * as glebeReducer from './view/glebe/glebe.reducer';
import * as importGlebeFormReducer from './view/glebe/import-glebe-form/import-glebe-form.reducer';
import * as harvestFormReducer from './view/harvest/harvest-form/harvest-form.reducer';
import * as harvestReducer from './view/harvest/harvest.reducer';
import * as homeReducer from './view/home/home.reducer';
import * as defineFieldFormReducer from './view/import-kmz/define-field-form/define-field-form.reducer';
import * as importKmzFormReducer from './view/import-kmz/import-kmz-form/import-kmz-form.reducer';
import * as importKmzReducer from './view/import-kmz/import-kmz.reducer';
import * as reportReducer from './view/report/report.reducer';
import * as reportFruitReducer from './view/report-fruit/report-fruit.reducer';
import * as trapReportFormReducer from './view/trap-report/trap-report-form/trap-report-form.reducer';
import * as trapReportReducer from './view/trap-report/trap-report.reducer';
import * as importTrapFormReducer from './view/trap/import-trap-form/import-trap-form.reducer';
import * as updateUserReducer from './view/update-user/update-user.reducer';

/**
 * STATE
 */
import { OccurrenceSettingsState } from 'src/app/view/configuration/occurrence-settings/occurrence-settings.state';
import { SystemSettingsState } from 'src/app/view/configuration/system-settings/system-settings.state';
import { FarmState } from 'src/app/view/farm/farm.state';
import { FieldFormState } from 'src/app/view/field/field-form/field-form.state';
import { FieldState } from 'src/app/view/field/field.state';
import { ImportFieldFormState } from 'src/app/view/field/import-field-form/import-field-form.state';
import { ReapFormState } from 'src/app/view/field/reap-form/reap-form.state';
import { GlebeFormState } from 'src/app/view/glebe/glebe-form/glebe-form.state';
import { GlebeState } from 'src/app/view/glebe/glebe.state';
import { ImportGlebeFormState } from 'src/app/view/glebe/import-glebe-form/import-glebe-form.state';
import { GmTabsState } from 'src/app/view/gm-tabs/gm-tabs.state';
import { GmState } from 'src/app/view/gm/gm.state';
import { HarvestFormState } from 'src/app/view/harvest/harvest-form/harvest-form.state';
import { HarvestState } from 'src/app/view/harvest/harvest.state';
import { HeaderState } from 'src/app/view/header/header.state';
import { DefineFieldFormState } from 'src/app/view/import-kmz/define-field-form/define-field-form.state';
import { ImportKmzFormState } from 'src/app/view/import-kmz/import-kmz-form/import-kmz-form.state';
import { LoginState } from 'src/app/view/login/login.state';
import { TrapReportFormState } from 'src/app/view/trap-report/trap-report-form/trap-report-form.state';
import { TrapReportState } from 'src/app/view/trap-report/trap-reports.state';
import { ImportTrapFormState } from 'src/app/view/trap/import-trap-form/import-trap-form.state';
import { TrapState } from 'src/app/view/trap/trap.state';
import { UserState } from 'src/app/view/user/user-form/user.state';
import { CustomerState } from './view/customer/create-customer/create-customer.state';
import { ReportState } from './view/report/report.state';
import { ReportFruitState } from './view/report-fruit/report-fruit.state';
import { UpdateUserState } from './view/update-user/update-user.state';
import { RouteOptimizationState } from './view/route-optimization/route-optimization.state';
import { RoutesState } from './view/route-optimization/store/routes/route-route-optimizations.state';
import { CustomerRouteOptimizationState } from './view/route-optimization/store/customers/customers-route-optimization.state';
import { FarmsRouteOptimizationState } from './view/route-optimization/store/farms/farms-route-optimization.state';

export interface State {
  login: LoginState;
  createCustomer: CustomerState;
  header: HeaderState;
  user: UserState;
  farm: FarmState;
  gm: GmState;
  trap: TrapState;
  gmTabs: GmTabsState;
  fieldFormState: FieldFormState;
  fieldState: FieldState;
  importKmzState: ImportKmzState;
  importKmzFormState: ImportKmzFormState;
  homeState: any;
  harvestState: HarvestState;
  harvestFormState: HarvestFormState;
  reapFormState: ReapFormState;
  glebeState: GlebeState;
  glebeFormState: GlebeFormState;
  trapReportState: TrapReportState;
  trapReportFormState: TrapReportFormState;
  occurrenceSettingsState: OccurrenceSettingsState;
  systemSettingsState: SystemSettingsState;
  importGlebeFormState: ImportGlebeFormState;
  importFieldFormState: ImportFieldFormState;
  importTrapFormState: ImportTrapFormState;
  defineFieldFormState: DefineFieldFormState;
  updateUserState: UpdateUserState;
  reportState: ReportState;
  reportFruitState: ReportFruitState;
  routesState: RoutesState;
  routeOptimizationState: RouteOptimizationState;
  customersRouteOptimizationState: CustomerRouteOptimizationState;
  farmsRouteOptimizationState: FarmsRouteOptimizationState;
}

export const reducers: ActionReducerMap<State> = {
  login: loginReducer.reducer,
  createCustomer: customerReducer.reducer,
  header: headerReducer.reducer,
  user: user.reducer,
  farm: farm.reducer,
  gm: gm.reducer,
  trap: trap.reducer,
  gmTabs: gmTabs.reducer,
  customersRouteOptimizationState: customerRoutesOptimizationReducer.reducer,
  routeOptimizationState: routeOptimizationReducer.reducer,
  routesState: routesReducer.reducer,
  farmsRouteOptimizationState: farmsRoutesOptimizationReducer.reducer,
  fieldFormState: fieldFormReducer.reducer,
  fieldState: fieldReducer.reducer,
  importKmzState: importKmzReducer.reducer,
  importKmzFormState: importKmzFormReducer.reducer,
  homeState: homeReducer.reducer,
  harvestState: harvestReducer.reducer,
  harvestFormState: harvestFormReducer.reducer,
  reapFormState: reapFormReducer.reducer,
  glebeState: glebeReducer.reducer,
  glebeFormState: glebeFormReducer.reducer,
  trapReportState: trapReportReducer.reducer,
  trapReportFormState: trapReportFormReducer.reducer,
  occurrenceSettingsState: occurrenceSettingsReducer.reducer,
  systemSettingsState: systemSettingsReducer.reducer,
  importGlebeFormState: importGlebeFormReducer.reducer,
  importFieldFormState: importFieldFormReducer.reducer,
  importTrapFormState: importTrapFormReducer.reducer,
  defineFieldFormState: defineFieldFormReducer.reducer,
  updateUserState: updateUserReducer.reducer,
  reportState: reportReducer.reducer,
  reportFruitState: reportFruitReducer.reducer,
};

// Redux logger
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log('state', state);
    console.log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.ngrxDebug ? [] : [debug];
