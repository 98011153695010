import {
  EvolutionChart,
  //ChartWeather,
  Field,
  MonitoringReport,
  TrapReport,
  Occurrence,
  Farm,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { AccumulatedChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/AccumulatedChart';
import { FarmWeather } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Farm';
import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';
import { GlebeInfos } from 'src/app/interfaces/Report';

export const reportFruitStateDefault = {
  customerId: '',
  endDate: '',
  evolutionChart: [],
  evolutionChartByFields: null,
  farm: null,
  farmId: '',
  fields: [],
  harvestId: '',
  infos: [],
  loading: 0,
  loadingAccumulatedChart: 0,
  loadingEvolutionChartByFields: 0,
  loadingMadChart: 0,
  loadingWeatherChartByFields: 0,
  madChart: [],
  weatherChartFields: [],
  monitoringReport: [],
  occurrences: [],
  phone: '',
  reportAccumulated: null,
  reportFruit: null,
  selectedFieldId: '',
  showModal: false,
  startDate: '',
  updateEvolutionChartByFields: false,
} as ReportFruitState;

export interface ReportFruitState {
  customerId: string;
  endDate: string;
  evolutionChart: Array<EvolutionChart>;
  evolutionChartByFields: EvolutionChart | null;
  farm: Farm | null;
  farmId: string;
  fields: Array<Field>;
  harvestId: string;
  infos: Array<GlebeInfos>;
  loading: number;
  loadingAccumulatedChart: number;
  loadingEvolutionChartByFields: number;
  loadingMadChart: number;
  loadingWeatherChartByFields: number;
  madChart: Array<MadChart>;
  weatherChartFields?: Array<FarmWeather>;
  monitoringReport: Array<MonitoringReport>;
  occurrences: Array<Occurrence>;
  phone: string;
  reportAccumulated: AccumulatedChart | null;
  reportFruit: TrapReport | null;
  selectedFieldId: string;
  showModal: boolean;
  startDate: string;
  updateEvolutionChartByFields: boolean;
}

export const cardFarm = [];
// export const cardFarm = ['country', 'state', 'city', 'district'];
export const cardGlebe = ['cropName', 'varieties'];
export const cardField = ['area', 'glebe', 'harvestDate'];
