import { Validators } from '@angular/forms';
import {
  Crop,
  Field,
  TrapReport,
  WebUser,
  Glebe,
  Occurrence,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { CustomValidators } from '../../utils/custom-validators/custom-validators';
import { GenerateReportType, StatusReportType } from '@tarvos-ag/tarvos-firestore-models/src/enums';

export const trapReportFormStateDefault = {
  trapReports: [],
  users: [],
  crops: [],
  glebes: [],
  fields: [],
  occurrences: [],
  lastCount: 0,
  response: null,
  loading: 0,
  loadingModal: 0,
  closeModal: false,
  enableField: {
    period: false,
    startDate: false,
    endDate: false,
    crop: false,
    glebes: false,
    glebe: false,
    field: false,
    occurrence: false,
    infestation: false,
    sendData: false,
    users: false,
    alias: false,
  },
} as TrapReportFormState;

export const infestationOptions = ['medium', 'high', 'all'];
export const reportFormatOptions = ['interactive', 'interactiveFruit', 'mad']; //'georeferenced'
export const enableFieldDefault = {
  period: false,
  startDate: false,
  endDate: false,
  crop: false,
  glebes: false,
  glebe: false,
  field: false,
  occurrence: false,
  infestation: false,
  users: false,
} as EnableField;

export interface EnableField {
  period: boolean;
  startDate: boolean;
  endDate: boolean;
  crop: boolean;
  glebes: boolean;
  glebe: boolean;
  field: boolean;
  occurrence: boolean;
  infestation: boolean;
  users: boolean;
  alias: boolean;
  sendData: boolean;
}

export interface TrapReportFormState {
  trapReports: Array<TrapReport>;
  users: Array<WebUser>;
  crops: Array<Crop>;
  glebes: Array<Glebe>;
  fields: Array<Field>;
  occurrences: Array<Occurrence>;
  lastCount: number;
  response: { status: StatusReportType; id: string } | null;
  loading: number;
  loadingModal: number;
  closeModal: boolean;
  enableField: EnableField;
}

export const firstFormGroup = {
  type: [GenerateReportType.pdf, [Validators.required]],
} as any;

export const secondFormGroup = {
  id: [null],
  type: [null],
  name: [null, [Validators.required, Validators.minLength(2)]],
  alias: [
    null,
    [Validators.minLength(2), Validators.maxLength(64), CustomValidators.whitespaceValidator],
  ],
  period: [null, [Validators.required]],
  startDate: [null, [Validators.required]],
  endDate: [null, [Validators.required]],
  cropId: [null],
  usersIds: [[], [Validators.required]],
  glebeIds: [[], [Validators.required]],
  glebeId: [null, [Validators.required]],
  fieldIds: [[], [Validators.required]],
  occurrenceIds: [[], [Validators.required]],
  status: [StatusReportType.in_progress, [Validators.required]],
  sendOnlyData: [true],
  percentage: [0, [Validators.required]],
  infestationLevel: [null, [Validators.required]],
  reportFormat: [null, [Validators.required]],
} as any;

export const trapReportColumns = [
  'date',
  'farm',
  'trap',
  'latlong',
  'glebe',
  'field',
  'crop',
  'pheromone',
  'occurrence',
  'count',
];
