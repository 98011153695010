import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { AfterViewInit, Component, Input, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateTypes } from 'src/app/services/translation.service';
import { ApplicationService } from 'src/app/services/application.service';
import { XYChart } from '@amcharts/amcharts4/charts';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-media-mad-chart',
  templateUrl: './media-mad-chart.component.html',
  styleUrls: ['./media-mad-chart.component.scss'],
})
export class MediaMadChartComponent implements AfterViewInit {
  @Input() public set data(data: any) {
    this.mediaMadChart = data;
    // this.initChart();
  }

  public mediaMadChart!: any;
  private chart!: XYChart;

  constructor(
    public anotherTranslateService: TranslateService,
    public trans: TranslateTypes,
    public zone: NgZone,
    public applicationService: ApplicationService
  ) {}

  public ngAfterViewInit() {
    this.zone.runOutsideAngular(async () => {
      if (this.chart) {
        this.chart.dispose();
      }
      const data = this.mediaMadChart;
      // Call chart
      this.createChart(data);
    });
  }

  public createChart(mad: any): void {
    // Criar gráfico // IMPORTANTE: criar elemento no html com o id criado abaixo
    let chart = am4core.create('mediamadchartapp', am4charts.XYChart);

    chart.data = mad;

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'area';
    //categoryAxis.title.text = "Áreas";

    // First value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = '';
    valueAxis.min = 0;
    valueAxis.max = Math.ceil(Math.max(...mad.map((d: any) => d.value))) + 1;
    valueAxis.extraMax = 0.2;

    // Second value axis
    // var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.title.text = "Units sold";
    // valueAxis2.renderer.opposite = true;

    // First series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'area';
    series.name = 'Infestações';
    //series.tooltipText = "{name}: [bold]{valueY}[/]";
    //series.fill = am4core.color("red");
    //series.strokeWidth = 0;

    var columnTemplate = series.columns.template;
    columnTemplate.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    //columnTemplate.fillOpacity = .8;
    //columnTemplate.strokeOpacity = 0;
    //columnTemplate.fill = am4core.color("#5a5");
    columnTemplate.strokeWidth = 0;
    columnTemplate.column.cornerRadiusTopLeft = 5;
    columnTemplate.column.cornerRadiusTopRight = 5;

    // Add a bullet
    var label = series.bullets.push(new am4charts.LabelBullet());
    label.zIndex = 10;
    label.label.text = '[bold]{value}[/]';
    label.label.fill = am4core.color('#fff');
    label.dy = -20;

    // let bullet2 = series.bullets.push(new am4charts.Bullet());
    // let image = bullet2.createChild(am4core.Image);
    // image.href = "./assets/icons/report/ico-grafico-marcador.svg";
    // image.width = 40;
    // image.height = 40;
    // image.horizontalCenter = "middle";
    // image.verticalCenter = "middle";
    // image.opacity = .5;
    // image.dy = -20;

    var bullet = series.columns.template.createChild(am4core.Circle);
    //bullet.locationY = 0.5;
    bullet.align = 'center';
    bullet.fillOpacity = 0.5;
    bullet.fill = am4core.color('#333');
    bullet.radius = 20;
    bullet.dy = -20;

    // VERICAR COMO TRAZER DO BACK PADRÃO DO NÍVEL SE FOR NECESSÁRIO //////////////////////////
    columnTemplate.adapter.add('fill', function (fill, target: any) {
      if (target.dataItem.valueY > 1) {
        return am4core.color('#f44336');
      }
      if (target.dataItem.valueY > 0.5) {
        return am4core.color('#fbb13c');
      }
      if (target.dataItem.valueY > 0) {
        return am4core.color('#4eb862');
      }
      return fill;
    });

    // Second series
    // var series2 = chart.series.push(new am4charts.LineSeries());
    // series2.dataFields.valueY = "units";
    // series2.dataFields.categoryX = "country";
    // series2.name = "Units";
    // series2.tooltipText = "{name}: [bold]{valueY}[/]";
    // series2.strokeWidth = 3;
    // series2.yAxis = valueAxis2;

    // Add legend
    //chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    this.chart = chart;
  }

  public ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
