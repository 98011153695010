import { GenericHttpService } from 'src/app/services/generic-http.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ReportData } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/ReportData';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslateTypes } from 'src/app/services/translation.service';

import moment from 'moment';

import * as reportFruitAction from './report-fruit.actions';
import { MyToastrService } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { GenericFirestoreService } from 'src/app/services/generic-firestore.service';
import { ApplicationService } from 'src/app/services/application.service';
// import { MadChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Mad';

@Injectable()
export class ReportFruitEffects {
  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private trans: TranslateTypes,
    private genericHttpService: GenericHttpService,
    private genericFirestoreService: GenericFirestoreService,
    private applicationService: ApplicationService,
    private toastrService: MyToastrService,
    private router: Router
  ) {}

  /**
   * Este método chama um serviço para buscar o relatório.
   */
  public getReportFruit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportFruitAction.GET_REPORT_FRUIT),
      switchMap((action) => {
        const reportUrl = `getReportFruit?token=${action.token}&ux=${action.ux}`;
        return this.genericHttpService.getById<any>(reportUrl).pipe(
          map((report: any) => {
            this.applicationService.setSystemSettings(report.systemSettings);
            return reportFruitAction.GET_REPORT_FRUIT_SUCCESS({
              customerId: report.customerId,
              farm: report.farm,
              farmId: report.farmId,
              fields: report.fields,
              harvestId: report.harvestId,
              occurrences: report.occurrences,
              reportFruit: report.report,
              showModal: report.showModal,
              data: {
                monitoringReport: report.data.monitoring.monitoringReport,
                evolutionChart: report.data.evolution,
                infos: report.data.infos,
                startDate: moment(new Date(report.data.monitoring.startDate)).format(
                  this.translateService.instant(this.trans.text.date)
                ),
                endDate: moment(new Date(report.data.monitoring.endDate)).format(
                  this.translateService.instant(this.trans.text.date)
                ),
                weatherChartFields: report.farm.weather,
              } as ReportData,
            });
          }),
          catchError((error) => {
            this.toastrService.error(
              `${
                error.code !== undefined
                  ? error.code
                  : error.error.code !== undefined
                  ? error.error.code
                  : error.error.internalCode
              }`
            );
            if (error.error.code === 'backend.report.create.generic')
              this.router.navigate(['login']);
            return of(reportFruitAction.GET_REPORT_FRUIT_FAIL(error));
          })
          // )
        );
      })
    );
  });

  /**
   * Este método chama um serviço para evolução dos levantamentos por talhões.
   */
  public getReportEvolutionChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportFruitAction.GET_EVOLUTION_CHART),
      switchMap((action) =>
        this.genericHttpService
          .getAll<any>(
            `chart?type=reportEvolutionByFields&customerId=${action.customerId}&farmId=${action.farmId}&startDate=${action.startDate}&endDate=${action.endDate}&fieldIds=${action.fieldIds}&occurrenceId=${action.occurrenceId}&harvestId=${action.harvestId}`
          )
          .toPromise()
          .then((evolutionChart: any) => {
            return reportFruitAction.GET_EVOLUTION_CHART_SUCCESS({ evolutionChart });
          })
          .catch((error) => {
            return reportFruitAction.GET_EVOLUTION_CHART_FAIL(error);
          })
      )
    )
  );

  /*
  // Weather data is in farm doc
    public getWeatherChart$ = createEffect(() =>
      this.actions$.pipe(
        ofType(reportFruitAction.GET_WEATHER_CHART),
        switchMap(() =>
          this.genericHttpService
            .getAll<any>(`chartWeather`)
            .toPromise()
            .then((weatherChart: any) => {
              return reportFruitAction.GET_WEATHER_CHART_SUCCESS({ weatherChart });
            })
            .catch((error) => {
              return reportFruitAction.GET_WEATHER_CHART_FAIL(error);
            })
        )
      )
    );
  */

  /**
   * Este método chama um serviço para evolução dos levantamentos por talhões.
   */
  // public getReportAccumulatedChart$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(reportFruitAction.GET_ACCUMULATED_CHART),
  //     switchMap((action) =>
  //       this.genericHttpService
  //         .getAll<any>(
  //           `chart?type=accumulatedCounts&customerId=${action.customerId}&farmId=${action.farmId}&harvestId=${action.harvestId}&fieldId=${action.fieldId}&occurrenceId=${action.occurrenceId}&endDate=${action.endDate}&days=${action.days}`
  //         )
  //         .toPromise()
  //         .then((reportAccumulated: any) => {
  //           return reportFruitAction.GET_ACCUMULATED_CHART_SUCCESS({ reportAccumulated });
  //         })
  //         .catch((error) => {
  //           return reportFruitAction.GET_ACCUMULATED_CHART_FAIL(error);
  //         })
  //     )
  //   )
  // );

  /**
   * Este método chama um serviço para mad por gleba.
   */
  // public getReportMadChart$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(reportFruitAction.GET_MAD_CHART),
  //     switchMap((action) =>
  //       this.genericHttpService
  //         .getAll<any>(
  //           `chart?type=mad&customerId=${action.customerId}&farmId=${action.farmId}&startDate=${action.startDate}&endDate=${action.endDate}&glebeName=${action.glebeName}&harvestId=${action.harvestId}`
  //         )
  //         .toPromise()
  //         .then((madChart: Array<MadChart>) => {
  //           return reportFruitAction.GET_MAD_CHART_SUCCESS({ madChart });
  //         })
  //         .catch((error) => {
  //           return reportFruitAction.GET_MAD_CHART_FAIL(error);
  //         })
  //     )
  //   )
  // );

  /**
   * Este método chama um serviço para exibir o link do whatsapp.
   */
  public getPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportFruitAction.GET_PHONE),
      switchMap(() => {
        return this.genericFirestoreService
          .getAll<String>(`supportPhone`)
          .first()
          .pipe(
            map((phone: any) => {
              return reportFruitAction.GET_PHONE_SUCCESS({ phone: phone[0].phone });
            }),
            catchError((error) => of(reportFruitAction.GET_PHONE_FAIL(error)))
          );
      })
    )
  );
}
