<mat-drawer-container fxFlexFill autosize>
  <mat-drawer
    #drawer
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    position="end"
    class="material-container-drawer-menu"
  >
    <app-gm-tabs
      #appGmTabs
      *ngIf="mapMonitoringItem"
      (closeGmTabsEvent)="closeDrawer()"
      [setOptions]="{ mapMonitoringItem: mapMonitoringItem }"
    >
    </app-gm-tabs>
  </mat-drawer>

  <div *ngIf="state.fields && state.fields.length > 0">
    <mat-card>
      <!-- Componente google mapas -->
      <app-maps-general-monitoring
        #mapsGeneralMonitoring
        [mapMonitoring]="mapMonitoring"
        (mapMonitoringEvent)="openDrawer($event)"
        (closeGmTabsEvent)="closeDrawer()"
        [height]="mobileQuery.matches ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)'"
        [fieldSelectedId]="mapMonitoringItem !== null ? mapMonitoringItem.field.id : null"
        [startDate]="state.gmConfig.startDate"
        [endDate]="state.gmConfig.endDate"
        [componentName]="'gmMonitoring'"
        [occurrenceSelectedId]="getOccurrenceSelectedId()"
        [occurrenceSelectedType]="getOccurrenceSelectedType()"
        [hideCollectionPoint]="state.gmConfig.hideCollectionPoint"
        [showFieldWithHighControlLevel]="state.gmConfig.showFieldWithHighControlLevel"
        [traps]="state.traps"
      >
      </app-maps-general-monitoring>

      <!-- Componente previsão do tempo -->
      <app-weather-forecast
        #weatherForecast
        class="material-container-absolute-top-right"
      ></app-weather-forecast>

      <!-- Botões superior a esquerda -->
      <div class="material-container-absolute-top-left">
        <div fxLayout="column" fxLayoutAlign="space-between end" style="height: 0px">
          <div class="material-button-menu-custom" style="height: 0px">
            <!-- Períodos -->
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="picker.open()"
              *ngIf="this.state.occurrences.length > 0 && getOccurrenceName()"
            >
              {{ getRangeDateToString() }}
              <mat-date-range-input
                class="material-input-picker"
                [formGroup]="rangeDate"
                [rangePicker]="picker"
                [max]="maxDate"
              >
                <input matStartDate formControlName="startDate" />
                <input matEndDate formControlName="endDate" />
              </mat-date-range-input>
              <mat-date-range-picker #picker panelClass="material-range-piker">
                <mat-date-range-picker-actions>
                  <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                    <button mat-button matDateRangePickerCancel>
                      {{ trans.button.close | translate }}
                    </button>
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="menuPeriodSelectionChange()"
                      matDateRangePickerApply
                    >
                      {{ trans.button.save | translate }}
                    </button>
                  </div>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
              <mat-icon class="mat-select-arrow-wrapper">keyboard_arrow_down</mat-icon>
            </button>

            <!-- Ocorrências -->
            <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="menu">
              {{ getOccurrenceName() }}
              <mat-icon class="mat-select-arrow-wrapper">keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>

        <!-- Ocorrências -->
        <mat-menu #menu="matMenu">
          <span *ngFor="let occurrence of this.state.occurrences">
            <span>
              <button
                mat-menu-item
                (click)="menuOccurrenceSelectionChange(occurrence.id)"
                color="primary"
                [ngClass]="
                  state.gmConfigEdit.trapOccurrenceId === occurrence.id
                    ? 'material-item-select'
                    : ''
                "
              >
                {{ occurrence.name }}
              </button>
            </span>
          </span>
        </mat-menu>
      </div>

      <!-- Botões inferior a direita -->
      <div class="material-container-absolute-button-right">
        <div fxLayout="column" fxLayoutAlign="space-between end">
          <button
            mat-mini-fab
            [matBadge]="5"
            matTooltipClass="material-tooltip-class"
            [matTooltip]="trans.text.filters | translate"
            [matTooltipPosition]="'left'"
            [matBadgeHidden]="filterNumberApplied() <= 0"
            color="primary"
            (click)="openDialog()"
            [matBadge]="filterNumberApplied()"
            matBadgeColor="accent"
          >
            <i class="las la-filter"></i>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="mapsGeneralMonitoring.setFitBounds()"
            matTooltipClass="material-tooltip-class"
            [matTooltip]="trans.text.centerField | label"
            [matTooltipPosition]="'left'"
          >
            <i class="las la-map-marked"></i>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="mapsGeneralMonitoring.setZoom('zoon in')"
            matTooltipClass="material-tooltip-class"
            [matTooltip]="trans.text.zoomIn | translate"
            [matTooltipPosition]="'left'"
          >
            <i class="las la-plus"></i>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="mapsGeneralMonitoring.setZoom('zoon out')"
            matTooltipClass="material-tooltip-class"
            [matTooltip]="trans.text.zoomOut | translate"
            [matTooltipPosition]="'left'"
          >
            <i class="las la-minus"></i>
          </button>
        </div>
      </div>

      <!-- Legenda do mapa de calor -->
      <!-- TODO double check ngIfs in this file -->
      <div class="material-legend">
        <div class="material-gradient material-heat-color">
          <div class="material-labels">
            <span>{{ trans.text.high | translate }}</span>
            <span>{{ trans.text.medium | translate }} </span>
            <span>{{ trans.text.low | translate }}</span>
            <span>{{ trans.text.unmonitored | translate }}</span>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- Sem gleba cadastrada -->
  <app-empty-states-box
    *ngIf="state.glebes.length <= 0"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-globe-americas'"
    [message]="trans.text.gmGlebeNotFound | label"
    [buttonOne]="trans.button.createGlebe | label"
    [buttonTwo]="trans.button.importGlebe | label"
    (buttonOneClick)="redirectAnotherPage('create-glebe', '/home/glebe')"
    (buttonTwoClick)="redirectAnotherPage('import-glebe', '/home/glebe')"
  ></app-empty-states-box>

  <!-- Sem talhão cadastrado -->
  <app-empty-states-box
    *ngIf="state.glebes.length > 0 && state.fields.length <= 0"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-globe-americas'"
    [message]="trans.text.gmFieldNotFound | label"
    [buttonOne]="trans.button.createField | label"
    [buttonTwo]="trans.button.importField | label"
    (buttonOneClick)="redirectAnotherPage('create-field', '/home/field')"
    (buttonTwoClick)="redirectAnotherPage('import-field', '/home/field')"
  ></app-empty-states-box>
</mat-drawer-container>
