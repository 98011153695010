import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { Customer, Farm, Point } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import * as RouteOptimizationActions from './route-optimization.actions';
import * as RouteOptimizationSelectors from './route-optimization.selectors';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-route-optimization',
  templateUrl: './route-optimization.component.html',
  styleUrls: ['./route-optimization.component.scss'],
})
export class RouteOptimizationComponent implements OnInit, OnDestroy {
  clients$: Observable<Customer[]>;
  farms$: Observable<Farm[]>;
  routeSegments$: Observable<Array<Array<{ lat: number; lng: number }>> | null>;
  optimizedOrder$: Observable<Point[]>;
  loadingCustomers$: Observable<boolean>;
  loadingFarms$: Observable<boolean>;
  durations$: Observable<number[]>;
  totalDuration$: Observable<number>;
  loading$: Observable<boolean>;
  error$: Observable<any>;

  combinedData$: Observable<{
    routeSegments: Array<Array<{ lat: number; lng: number }>> | null;
    optimizedOrder: Point[];
    durations: number[];
    totalDuration: number;
  }>;

  clients: Customer[] = [];
  farms: Farm[] = [];
  selectedClientId: string = '';
  selectedFarms: Farm[] = [];
  routeSegments: Array<Array<{ lat: number; lng: number }>> = [];
  routeReport: Array<{
    begin: string;
    end: string;
    endFarmId: string;
    selected: boolean;
  }> = [];
  subscriptions: Subscription = new Subscription();

  mapCenter: { lat: number; lng: number } = { lat: -15.7942, lng: -47.8822 };
  mapZoom: number = 4;

  startPoint: Point = { id: 'start', lat: -9.4575972, lng: -40.4940257 };
  homePoint: Point = { id: 'home', lat: -9.4575972, lng: -40.4940257 };
  markers: Array<{
    lat: number;
    lng: number;
    label?: string;
    iconUrl?: string;
  }> = [];

  selectedFarmId: string = '';
  currentClientIdForFarms: string = '';
  farmClientMap: { [farmId: string]: string } = {};
  colors = ['red', 'blue', 'green', 'orange', 'purple', 'yellow', 'cyan', 'magenta'];

  startAddress: string = '';
  endAddress: string = '';
  startCoordinates: { lat: number; lng: number } | null = null;
  endCoordinates: { lat: number; lng: number } | null = null;
  durationsArray: number[] = [];

  filteredRouteSegments: Array<Array<{ lat: number; lng: number }>> = [];
  filteredDurations: number[] = [];
  filteredFarmNames: string[] = [];
  filteredSelectedFarms: Farm[] = [];

  filteredRouteReport: Array<{
    begin: string;
    end: string;
    endFarmId: string;
    selected: boolean;
  }> = [];

  totalDuration: number = 0;

  sameAsStart: boolean = false;

  private backendUrl: string = 'https://internotarvos.pro/api/routeOptimization';

  constructor(private store: Store<any>, private http: HttpClient, private snackBar: MatSnackBar) {
    this.clients$ = this.store.pipe(select(RouteOptimizationSelectors.selectAllClients));
    this.farms$ = this.store.pipe(select(RouteOptimizationSelectors.selectAllFarms));
    this.routeSegments$ = this.store.pipe(
      select(RouteOptimizationSelectors.selectCurrentRouteSegments)
    );
    this.optimizedOrder$ = this.store.pipe(select(RouteOptimizationSelectors.selectOptimizedOrder));
    this.durations$ = this.store.pipe(select(RouteOptimizationSelectors.selectDurations));
    this.totalDuration$ = this.store.pipe(select(RouteOptimizationSelectors.selectTotalDuration));
    this.loading$ = this.store.pipe(select((state) => state.loading));
    this.error$ = this.store.pipe(select((state) => state.error));
    this.loadingCustomers$ = this.store.pipe(
      select(RouteOptimizationSelectors.selectLoadingCustomers)
    );
    this.loadingFarms$ = this.store.pipe(select(RouteOptimizationSelectors.selectLoadingFarms));

    this.combinedData$ = combineLatest([
      this.routeSegments$,
      this.optimizedOrder$,
      this.durations$,
    ]).pipe(
      map(([routeSegments, optimizedOrder, durations]) => ({
        routeSegments,
        optimizedOrder,
        durations,
        totalDuration: durations.reduce((acc, curr) => acc + curr, 0),
      }))
    );
  }

  ngOnInit(): void {
    this.store.dispatch(RouteOptimizationActions.GET_CUSTOMERS());

    this.subscriptions.add(
      this.combinedData$.subscribe(
        ({ routeSegments, optimizedOrder, durations, totalDuration }) => {
          if (routeSegments && optimizedOrder && durations) {
            if (routeSegments.length !== durations.length) {
              console.error('Mismatch between routeSegments and durations lengths.');
              this.snackBar.open('Erro: Dados de rota inconsistentes.', 'Fechar', {
                duration: 3000,
              });
              return;
            }

            this.routeSegments = routeSegments;
            this.durationsArray = durations;

            if (this.routeSegments.length > 0 && this.routeSegments[0].length > 0) {
              this.mapCenter = {
                lat: this.routeSegments[0][0].lat,
                lng: this.routeSegments[0][0].lng,
              };
            }

            if (optimizedOrder && optimizedOrder.length > 0) {
              this.updateSelectedFarmsOrder(optimizedOrder);
            }

            this.generateRouteReport();
            this.updateFilteredData();
          }
        }
      )
    );

    this.subscriptions.add(
      this.clients$.subscribe((clients) => {
        this.clients = clients;
      })
    );

    this.subscriptions.add(
      this.farms$.subscribe((farms) => {
        this.farms = farms;
        // console.log('Fazendas no componente:', this.farms);
      })
    );

    this.updateMarkers();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleClientChange(): void {
    const clientId = this.selectedClientId;
    if (clientId) {
      this.currentClientIdForFarms = clientId;
      this.store.dispatch(RouteOptimizationActions.GET_FARMS({ customerId: clientId }));
    }
  }

  handleFarmSelection(): void {
    const farmId = this.selectedFarmId;
    const farmSelected = this.farms.find((farm) => farm.id === farmId);

    if (farmSelected && !this.selectedFarms.some((farm) => farm.id === farmSelected.id)) {
      this.selectedFarms.push(farmSelected);
      this.farmClientMap[farmId] = this.currentClientIdForFarms;
      this.updateMarkers();
    } else {
      console.warn(`Fazenda com ID ${farmId} já está selecionada.`);
      this.snackBar.open('Fazenda já selecionada.', 'Fechar', { duration: 3000 });
    }

    this.selectedFarmId = '';
  }

  removeFarm(farm: Farm): void {
    this.selectedFarms = this.selectedFarms.filter((f) => f.id !== farm.id);
    delete this.farmClientMap[farm.id];
    this.updateMarkers();
    this.snackBar.open('Fazenda removida da seleção.', 'Fechar', { duration: 3000 });

    this.generateRouteReport();
  }

  getClientNameForFarm(farmId: string): string {
    const clientId = this.farmClientMap[farmId];
    return this.getClientName(clientId);
  }

  getClientName(clientId: string): string {
    const client = this.clients.find((c) => c.id === clientId);
    return client ? client.name : 'Desconhecido';
  }

  updateMarkers(): void {
    const farmMarkers = this.selectedFarms
      .filter((farm) => farm && farm.coordinates)
      .map((farm, index) => {
        const lat = parseFloat(farm.coordinates.lat as any);
        const lng = parseFloat(farm.coordinates.lng as any);
        if (isNaN(lat) || isNaN(lng)) {
          console.error(
            `Coordenadas inválidas para farm ${farm.id}: lat=${farm.coordinates.lat}, lng=${farm.coordinates.lng}`
          );
          return null;
        }
        return {
          lat,
          lng,
          label: '',
          iconUrl: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        };
      })
      .filter((marker) => marker !== null) as Array<{
      lat: number;
      lng: number;
      label: string;
      iconUrl: string;
    }>;

    const startLat = parseFloat(this.startPoint.lat as any);
    const startLng = parseFloat(this.startPoint.lng as any);
    const homeLat = parseFloat(this.homePoint.lat as any);
    const homeLng = parseFloat(this.homePoint.lng as any);

    if (isNaN(startLat) || isNaN(startLng)) {
      console.error('Coordenadas inválidas para o ponto de início:', this.startPoint);
    }

    if (isNaN(homeLat) || isNaN(homeLng)) {
      console.error('Coordenadas inválidas para o ponto de casa:', this.homePoint);
    }

    const startMarker = {
      lat: startLat,
      lng: startLng,
      label: 'C',
      iconUrl: 'assets/icons/home.svg',
    };

    const homeMarker = {
      lat: homeLat,
      lng: homeLng,
      label: 'R',
      iconUrl: 'assets/icons/home.svg',
    };

    this.markers = [startMarker, homeMarker, ...farmMarkers];

    // console.log('Markers updated:', this.markers);
    this.calculateMapCenter({ zoom: 10 });
  }

  updateFilteredData(): void {
    this.filteredRouteSegments = [];
    this.filteredDurations = [];
    this.filteredFarmNames = [];
    this.filteredSelectedFarms = [];
    this.filteredRouteReport = [];

    this.routeReport.forEach((route, index) => {
      if (route.selected) {
        this.filteredRouteSegments.push(this.routeSegments[index]);
        this.filteredDurations.push(this.durationsArray[index]);
        this.filteredFarmNames.push(route.end);
        this.filteredRouteReport.push(route);

        const farm = this.selectedFarms.find((farm) => farm.id === route.endFarmId);
        if (farm) {
          this.filteredSelectedFarms.push(farm);
        }
      }
    });

    this.totalDuration = this.filteredDurations.reduce((acc, curr) => acc + curr, 0);

    // console.log('Filtered Route Segments:', this.filteredRouteSegments);
    // console.log('Filtered Durations:', this.filteredDurations);
    // console.log('Filtered Farm Names:', this.filteredFarmNames);
    // console.log('Filtered Selected Farms:', this.filteredSelectedFarms);
    // console.log('Filtered Route Report:', this.filteredRouteReport);
    // console.log('Total Duration:', this.totalDuration);

    this.updateMarkers();
    this.calculateMapCenter({ zoom: 10 });
  }

  geocodeStartAddress(): void {
    if (this.startAddress.trim() === '') {
      console.warn('Endereço de partida está vazio');
      this.snackBar.open('Por favor, insira um endereço de partida válido.', 'Fechar', {
        duration: 3000,
      });
      return;
    }
    this.geocodeAddress(this.startAddress).subscribe(
      (coords) => {
        this.startCoordinates = coords;
        this.startPoint = { id: 'start', lat: coords.lat, lng: coords.lng };
        if (this.sameAsStart && this.startCoordinates) {
          this.endAddress = this.startAddress;
          this.endCoordinates = this.startCoordinates;
          this.homePoint = { id: 'home', lat: coords.lat, lng: coords.lng };
          this.snackBar.open('Endereço de retorno definido como o endereço de partida.', 'Fechar', {
            duration: 3000,
          });
        }
        this.updateMarkers();
        this.calculateMapCenter({ zoom: 10 });
      },
      (error) => {
        console.error('Falha ao geocodificar o endereço de partida:', error);
        this.snackBar.open(
          'Falha ao geocodificar o endereço de partida. Verifique e tente novamente.',
          'Fechar',
          { duration: 3000 }
        );
      }
    );
  }

  geocodeEndAddress(): void {
    if (this.endAddress.trim() === '') {
      console.warn('Endereço de retorno está vazio');
      this.snackBar.open('Por favor, insira um endereço de retorno válido.', 'Fechar', {
        duration: 3000,
      });
      return;
    }
    this.geocodeAddress(this.endAddress).subscribe(
      (coords) => {
        this.endCoordinates = coords;
        this.homePoint = { id: 'home', lat: coords.lat, lng: coords.lng };
        this.updateMarkers();
        this.calculateMapCenter({ zoom: 10 });
        this.snackBar.open('Endereço de retorno definido com sucesso.', 'Fechar', {
          duration: 3000,
        });
      },
      (error) => {
        console.error('Falha ao geocodificar o endereço de retorno:', error);
        this.snackBar.open(
          'Falha ao geocodificar o endereço de retorno. Verifique e tente novamente.',
          'Fechar',
          { duration: 3000 }
        );
      }
    );
  }

  geocodeAddress(address: string): Observable<{ lat: number; lng: number }> {
    const url = `${this.backendUrl}/geodecode`;
    return this.http.post<any>(url, { address }).pipe(
      map((response) => {
        if (response.lat !== undefined && response.lng !== undefined) {
          return { lat: response.lat, lng: response.lng };
        } else {
          throw new Error('Geocodificação falhou: resposta inválida');
        }
      }),
      catchError((err) => {
        console.error('Erro na geocodificação:', err);
        throw err;
      })
    );
  }

  onSameAsStartChange(): void {
    if (this.sameAsStart) {
      if (this.startCoordinates) {
        this.endAddress = this.startAddress;
        this.endCoordinates = this.startCoordinates;
        this.homePoint = {
          id: 'home',
          lat: this.startCoordinates.lat,
          lng: this.startCoordinates.lng,
        };
        this.snackBar.open('Endereço de retorno definido como o endereço de partida.', 'Fechar', {
          duration: 3000,
        });
      } else {
        console.warn('Geocodifique o endereço de partida antes de marcar o checkbox');
        this.snackBar.open(
          'Defina o endereço de partida antes de selecionar o mesmo endereço de retorno.',
          'Fechar',
          { duration: 3000 }
        );
        this.sameAsStart = false;
      }
    } else {
      this.endAddress = '';
      this.endCoordinates = null;
      this.homePoint = { id: 'home', lat: -9.4575972, lng: -40.4940257 };
      this.snackBar.open('Você pode inserir um endereço de retorno diferente agora.', 'Fechar', {
        duration: 3000,
      });
    }
    this.updateMarkers();
    this.calculateMapCenter({ zoom: 10 });
  }

  updateSelectedFarmsOrder(optimizedOrder: Point[]): void {
    const optimizedFarms = optimizedOrder.slice(1, -1);
    // console.log('Optimized Farms:', optimizedFarms);

    const reorderedFarms = optimizedFarms
      .map((optFarm) => {
        return this.selectedFarms.find((farm) => farm.id === optFarm.id);
      })
      .filter((farm) => farm !== undefined) as Farm[];

    // console.log('Reordered Farms:', reorderedFarms);

    if (reorderedFarms.length === this.selectedFarms.length) {
      this.selectedFarms = reorderedFarms;
      // console.log('selectedFarms updated:', this.selectedFarms);
    } else {
      // console.warn('A ordem otimizada não corresponde ao número de fazendas selecionadas.');
      this.snackBar.open(
        'A ordem otimizada não corresponde ao número de fazendas selecionadas.',
        'Fechar',
        { duration: 3000 }
      );
    }

    this.updateMarkers();
  }

  calculateMapCenter({ zoom = 6 }): void {
    if (this.markers.length === 0) return;

    const totalPoints = this.markers.length;
    const sumLat = this.markers.reduce((sum, marker) => sum + marker.lat, 0);
    const sumLng = this.markers.reduce((sum, marker) => sum + marker.lng, 0);

    this.mapCenter = {
      lat: sumLat / totalPoints,
      lng: sumLng / totalPoints,
    };

    this.mapZoom = zoom;
  }

  calculateRoute(): void {
    if (!this.startCoordinates || !this.homePoint) {
      console.warn('Endereço de partida e retorno devem ser definidos');
      this.snackBar.open(
        'Defina os endereços de partida e retorno antes de calcular a rota.',
        'Fechar',
        { duration: 3000 }
      );
      return;
    }

    const farmsPoints: Point[] = this.selectedFarms.map((farm) => ({
      id: farm.id,
      lat: parseFloat(farm.coordinates.lat as any),
      lng: parseFloat(farm.coordinates.lng as any),
    }));
    // console.log('Calculando rota com os seguintes pontos:', farmsPoints);
    this.store.dispatch(
      RouteOptimizationActions.CALCULATE_ROUTE({
        startPoint: this.startPoint,
        homePoint: this.homePoint,
        farmsPoints: farmsPoints,
      })
    );

    this.routeReport = [];
    this.snackBar.open('Rota sendo calculada...', 'Fechar', { duration: 3000 });
  }

  generateRouteReport(): void {
    this.routeReport = [];

    const routePoints = [
      { name: 'Início', lat: this.startPoint.lat, lng: this.startPoint.lng },
      ...this.selectedFarms.map((farm) => ({
        name: farm.name,
        lat: parseFloat(farm.coordinates.lat as any),
        lng: parseFloat(farm.coordinates.lng as any),
        id: farm.id,
      })),
      { name: 'Retorno', lat: this.homePoint.lat, lng: this.homePoint.lng },
    ];

    for (let i = 0; i < this.routeSegments.length; i++) {
      const begin = routePoints[i];
      const end = routePoints[i + 1];

      const endFarmId = this.selectedFarms[i]?.id || '';

      this.routeReport.push({
        begin: begin.name || `Fazenda ${begin.name}`,
        end: end.name || `Fazenda ${end.name}`,
        endFarmId: endFarmId,
        selected: true,
      });
    }

    this.updateMarkers();
    this.calculateMapCenter({ zoom: 4 });
    this.updateFilteredData();
  }

  /**
   * Formata a duração de segundos para horas, minutos e segundos (e.g., 3665 segundos -> "1h 1m 5s")
   * @param seconds Número de segundos
   * @returns String formatada
   */
  formatDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }
}
