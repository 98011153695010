export const IconCustom = {
  url: '',
  scaledSize: { height: 16, width: 16 },
  anchor: { x: 12, y: 12 },
};

export const OccurrenceUrl = {
  OCCURRENCE: {
    ARMIGERA: 'insect_one.png',
    FRUGIPERDA: 'insect_two.png',
    SACCHARALIS: 'insect_one.png',
    TRACA_TOMATEIRO: 'insect_three.png',
    BROCA_PEQUENA_FRUTO: 'insect_four.png',
    CIGARRINHA_DO_MILHO_ADULTO: 'insect_three.png',
    CAPITATA: 'insect_one.png',
    ANASTREPHA: 'insect_two.png',
    VIRESCENS: 'insect_one.png',
  },
  OCCURRENCE_HEAT: {
    HIGH: {
      ARMIGERA: 'insect_high.svg',
      CAPITATA: 'insect_high.svg',
      ANASTREPHA: 'insect_anastrepha_high.svg',
    },
    MEDIUM: {
      ARMIGERA: 'insect_medium.svg',
      CAPITATA: 'insect_medium.svg',
      ANASTREPHA: 'insect_anastrepha_medium.svg',
    },
    MODERATE: {
      ARMIGERA: 'insect_low.svg',
      CAPITATA: 'insect_low.svg',
      ANASTREPHA: 'insect_anastrepha_low.svg',
    },
    ZERO: {
      ARMIGERA: 'insect_low.svg',
      CAPITATA: 'insect_low.svg',
      ANASTREPHA: 'insect_anastrepha_low.svg',
      FRUGIPERDA: 'insect_low.svg',
      SACCHARALIS: 'insect_low.svg',
      TRACA_TOMATEIRO: 'insect_low.svg',
      BROCA_PEQUENA_FRUTO: 'insect_low.svg',
      CIGARRINHA_DO_MILHO_ADULTO: 'insect_low.svg',
      VIRESCENS: 'insect_low.svg',
    },
  },
} as any;
