<div class="container" fxFlex="raw">
  <button class="btn-close" (click)="close()">
    <i class="las la-times"></i>
  </button>
  <div fxFlex="column">
    <div class="title">Temos novidades!</div>
    <div class="description">
      Com o objetivo de facilitar a visualização do gráfico, realizamos uma pequena melhoria:
    </div>
    <ul class="items">
      <li class="item">
        Por padrão, a linha referente ao valor TOTAL está oculta, o que auxilia no melhor ajuste da
        escala do gráfico.
      </li>
      <li class="item">Para visualizá-la, basta clicar no item "Total", na legenda do gráfico.</li>
      <!-- <li class="item" *ngIf="data.isFruit">
        Também adicionamos o gráfico MAD, para que você possa acompanhá-lo também neste relatório
        interativo
      </li> -->
    </ul>
    <!-- <div class="description-2">
      Agradecemos suas sugestões e continuaremos trabalhando para entregar sempre o melhor serviço.
    </div> -->
  </div>
  <!-- <div class="np-close-btn btn-close" title="Close">X</div> -->
</div>
