export const label = {
  pageNotFound: 'Desculpe, a página que você estava procurando não existe ou foi removida.',
  'in progress': 'Em  progresso',
  absence: 'Ausência',
  alias: 'Apelido',
  allotment: 'Lote',
  amount: 'Quantidade',
  area: 'Área',
  areaString: 'Área',
  average: 'Média',
  batteryLevel: 'Nível da bateria',
  ccc: 'CCC',
  charts: 'Gráficos',
  city: 'Cidade',
  clearSorting: ' Sem ordenação',
  collectPoint: 'Ponto de coleta',
  columns: 'Colunas',
  confirm: 'Confirmar',
  confirmPassword: 'Confirmar senha',
  controlLevel: 'Nível de controle',
  count: 'Contagem',
  country: 'País',
  crop: 'Cultura',
  cropName: 'Cultura',
  cropVarieties: 'Variedade da Cultura',
  currentYear: 'Ano',
  customerName: 'Nome da empresa',
  damageLevel: 'Nível de dano',
  date: 'Data',
  notDefined: {
    default: 'Label não definida',
    type: 'generic',
  },
  defineTheKmzPolygons:
    'Selecione alguns talhões no mapa para definir sua gleba e outras informações importantes',
  device: 'Dispositivo',
  deviceName: 'Dispositivo',
  distanceBetweenLines: 'Distância entre linhas',
  distanceBetweenPlants: 'Distância entre plantas',
  district: 'Perímetro irrigado',
  drawOrDragPointToTrap:
    'Clique sobre o talhão para especificar a posição da armadilha. Também é possível arrastá-la para reposicioná-la',
  drawOrDragTrap:
    'Clique no mapa para especificar os locais onde as armadilhas deverão ser posicionadas',
  drawPolygonToField: {
    default: 'Desenhe um polígono no mapa para delimitar o talhão',
    lot: 'Desenhe um polígono no mapa para delimitar o lote',
    type: 'field',
  },
  email: 'E-mail',
  endDate: 'Data final',
  endOfTimeType: 'Tipo de término',
  fallowingUntil: 'Período de pousio',
  fallowingUntilDate: 'Período de pousio',
  farm: {
    default: 'Fazenda',
    productiveUnit: 'Unidade Produtiva',
    type: 'farm',
  },
  farmIds: {
    default: 'Fazenda',
    productiveUnit: 'Unidade Produtiva',
    type: 'farm',
  },
  farmName: {
    default: 'Fazenda',
    productiveUnit: 'Unidade Produtiva',
    type: 'farm',
  },
  farms: {
    default: 'Fazendas',
    productiveUnit: 'Unidades Produtivas',
    type: 'farm',
  },
  field: {
    default: 'Talhão',
    lot: 'Lote',
    type: 'field',
  },
  fieldArea: {
    default: 'Área do talhão',
    lot: 'Área do lote',
    type: 'field',
  },
  fieldIds: {
    default: 'Talhões',
    lot: 'Lotes',
    type: 'field',
  },
  fieldName: {
    default: 'Talhão',
    lot: 'Lote',
    type: 'field',
  },
  fillFactor: 'Fator de enchimento',
  fillFactorUrgency: 'Fator de enchimento de piso alto',
  finished: 'Finalizada',
  format: 'Formato',
  general: 'Geral',
  glebe: {
    default: 'Gleba',
    productionUnit: 'Unidade de Produção',
    productiveUnit: 'Unidade Produtiva',
    type: 'glebe',
  },
  glebeIds: {
    default: 'Glebas',
    productionUnit: 'Unidades de Produção',
    productiveUnit: 'Unidades Produtivas',
    type: 'glebe',
  },
  glebeName: {
    default: 'Gleba',
    productionUnit: 'Unidade de Produção',
    productiveUnit: 'Unidade Produtiva',
    type: 'glebe',
  },
  govCode: 'Propriedade',
  harvest: {
    default: 'Safra',
    year: 'Ano',
    cycle: 'Ciclo',
    type: 'harvest',
  },
  harvestDate: 'Data da colheita',
  harvestFieldPeriodDate: 'Período da colheita',
  harvestPercentage: 'Porcentagem da colheita',
  harvestName: {
    default: 'Nome da safra',
    year: 'Nome do ano',
    cycle: 'Nome do ciclo',
    type: 'harvest',
  },
  harvestPeriod: {
    default: 'Período da safra',
    year: 'Período do ano',
    cycle: 'Período do ciclo',
    type: 'harvest',
  },
  harvestYear: 'Ano',
  high: 'Alta',
  highInfestation: 'Alta infestação',
  humidity: 'Umidade',
  importDate: 'Data de importação',
  importedHarvest: {
    default: 'Importar safra',
    year: 'Importar ano',
    cycle: 'Importar ciclo',
    type: 'harvest',
  },
  importedPercentage: 'Porcentagem importada',
  inProgress: 'Em andamento',
  infestation: 'Infestação',
  infestationLevel: 'Enviar relatório quando o nível de infestação for',
  installationDate: 'Data de instalação',
  installedEquipments: 'Equipamentos instalados',
  allCrops: 'Todas as culturas',
  lastDate: 'Última atualização',
  lastUpdate: 'Última atualização',
  lastLinerExchange: 'Últ. troca de piso',
  lat: 'Latitude',
  latlong: 'Lat-Long',
  late: 'Atrasada',
  lineOfTime: 'Linha do tempo',
  linerDate: 'Data da troca',
  linerExchange: 'Urgência de período de troca de piso',
  lng: 'Longitude',
  low: 'Baixa',
  lowInfestation: 'Baixa infestação',
  medium: 'Média',
  moderate: 'Baixa',
  mediumInfestation: 'Média infestação',
  name: 'Nome',
  noCommunication: ' Falta de comunicação da armadilha',
  noMonitoring: 'Não Monitorado',
  observation: 'Observação',
  occurrence: 'Ocorrência',
  occurrenceIds: 'Ocorrências',
  occurrences: 'Ocorrências',
  officialTrap: 'Código oficial',
  optional: 'Opcional',
  orderBy: 'Ordenar por',
  password: 'Senha',
  percentage: 'Porcentagem',
  period: 'Período',
  pheromone: 'Atrativo',
  pheromoneExchange: 'Urgência de período de troca de atrativo',
  phone: 'Telefone',
  phoneNumber: 'Telefone',
  point: 'Ponto',
  pointCoords: 'Coordenadas do ponto',
  pointName: 'Nome do ponto',
  pointTraps: 'Coordenadas da armadilha',
  presence: 'Presença',
  productiveUnit: 'Unidade produtiva',
  program: 'Programa',
  repeat: 'Repetir',
  byTrap: 'por armadilha',
  reportDate: 'Data do relatório',
  reportFormat: 'Formato do relatório',
  requestOpen: 'Solicitação aberta',
  requestType: 'Tipo de solicitação',
  responsibleManager: 'Gestor responsável',
  role: 'Cargo',
  roleName: 'Cargo',
  sector: 'Setor',
  sendReport: 'Enviar relatório para',
  setControlLevel: 'Configurar nível de controle',
  startDate: 'Data inicial',
  state: 'Estado',
  status: 'Status',
  statusName: 'Status',
  temperature: 'Temperatura',
  ticketId: 'Id do dispositivo',
  total: 'Total',
  trap: 'Armadilha',
  trapCode: 'Código Tarvos',
  trapIds: 'Armadilhas',
  trapType: 'Tipo da armadilha',
  traps: 'Armadilhas',
  trapsName: 'Armadilha',
  trapNoCommunication: 'Armadilha sem comunicação',
  type: 'Tipo',
  typeName: 'Tipo',
  types: 'Tipo de solicitações',
  unitsOfMeasure: 'Unidades de medidas',
  updatedAt: 'Última atualização',
  userResponsibleForImport: 'Responsável',
  value: 'Valor',
  varieties: 'Variedade',
  varietiesName: 'Variedade',
  visitsReport: 'Relatório de visitas',
  workRoute: 'Rota de trabalho',
  zero: 'Baixa',
};
