import * as moment from 'moment';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { RadarChart, XYChart } from '@amcharts/amcharts4/charts';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';
import { TranslateService } from '@ngx-translate/core';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR';
import { ApplicationService } from 'src/app/services/application.service';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const colors = [
  am4core.color('#f44336'),
  am4core.color('#2196f3'),
  am4core.color('#4caf50'),
  am4core.color('#ffc107'),
  am4core.color('#9c27b0'),
  am4core.color('#795548'),
  am4core.color('#9e9e9e'),
  am4core.color('#e91e63'),
  am4core.color('#03a9f4'),
  am4core.color('#8bc34a'),
  am4core.color('#00bcd4'),
  am4core.color('#cddc39'),
  am4core.color('#673ab7'),
  am4core.color('#ff5722'),
  am4core.color('#607d8b'),
  am4core.color('#ff9800'),
  am4core.color('#3f51b5'),
  am4core.color('#009688'),
];

export abstract class BaseGraph {
  public am4charts = am4charts;
  public am4core = am4core;
  public moment: any = moment;
  public translateService: TranslateService;

  public xYChart!: XYChart;
  public radarChart!: RadarChart;

  constructor(
    private refTranslateService: TranslateService,
    public applicationService: ApplicationService
  ) {
    this.translateService = this.refTranslateService;

    if (this.applicationService.getIsDarkTheme()) {
      am4core.useTheme(am4themes_dark);
    } else {
      am4core.unuseTheme(am4themes_dark);
    }
  }
  /**
   * Este método retorna a instância de um gráfico amCharts ( Colunas )
   */
  public createXYChart(htmlElement: HTMLElement | string, classType: any): void {
    this.xYChart = this.am4core.create(htmlElement, classType);
    this.xYChart.numberFormatter.numberFormat = '##.##';
    this.xYChart.exporting.menu = this.createExportGraph();
    this.xYChart.cursor = new this.am4charts.XYCursor();
    this.xYChart.colors.list = colors;

    this.configureLanguage();
  }

  /**
   * Este método retorna a instância de um gráfico amCharts ( Radar )
   */
  public createRadarChart(htmlElement: HTMLElement | string, classType: any): void {
    this.radarChart = this.am4core.create(htmlElement, classType);
    this.radarChart.numberFormatter.numberFormat = '##.##';
    this.radarChart.exporting.menu = this.createExportGraph();
    this.radarChart.colors.list = colors;

    this.configureLanguage();
  }

  /**
   * Este método cria a categoria do eixo x do gráfico ( Coluna )
   * @param category Categoria
   * @param text Texto adicional da categoria
   */
  public createBasicXYChartCategoryAxis(
    category: string,
    text: string
  ): am4charts.CategoryAxis<am4charts.AxisRenderer> {
    const categoryAxis = this.xYChart.xAxes.push(new this.am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.title.text = text;
    categoryAxis.title.fontSize = '14px';
    categoryAxis.fontSize = '10px';
    categoryAxis.renderer.minGridDistance = 130;

    if (categoryAxis.tooltip) {
      categoryAxis.tooltip.disabled = true;
    }

    return categoryAxis;
  }

  /**
   * Este método cria a categoria do eixo x do gráfico ( Radar )
   * @param category Categoria
   * @param text Texto adicional da categoria
   */
  public createBasicRadarChartCategoryAxis(
    category: string,
    text: string
  ): am4charts.CategoryAxis<am4charts.AxisRenderer> {
    const categoryAxis = this.radarChart.xAxes.push(new this.am4charts.CategoryAxis<any>());
    categoryAxis.dataFields.category = category;
    categoryAxis.title.text = text;
    categoryAxis.title.fontSize = '14px';
    categoryAxis.fontSize = '10px';
    categoryAxis.renderer.minGridDistance = 130;

    if (categoryAxis.tooltip) {
      categoryAxis.tooltip.disabled = true;
    }

    return categoryAxis;
  }

  /**
   * Este método cria o valor do eixo y do gráfico ( Coluna / linha )
   */
  public createBasicXYChartValueAxis(): am4charts.ValueAxis<am4charts.AxisRenderer> {
    const valueAxis = this.xYChart.yAxes.push(new this.am4charts.ValueAxis());
    if (valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
    }
    valueAxis.renderer.minGridDistance = 70;
    valueAxis.fontSize = '12px';

    return valueAxis;
  }

  /**
   * Este método cria o DateAxis do eixo x do gráfico ( Coluna / linha )
   */
  public createBasicXYChartDateAxis(): am4charts.DateAxis<am4charts.AxisRenderer> {
    const dateAxis = this.xYChart.xAxes.push(new this.am4charts.DateAxis());
    dateAxis.dateFormats.setKey('day', 'dd/MM');
    dateAxis.periodChangeDateFormats.setKey('day', 'dd/MM');
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.4;
    dateAxis.endLocation = 0.6;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.fontSize = '11px';
    if (dateAxis.tooltip) {
      dateAxis.tooltip.disabled = true;
    }

    return dateAxis;
  }

  /**
   * Este método cria o valor do eixo y do gráfico ( Radar )
   */
  public createBasicRadarChartValueAxis(): am4charts.ValueAxis<am4charts.AxisRenderer> {
    const valueAxis = this.radarChart.yAxes.push(new this.am4charts.ValueAxis<any>());
    if (valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
    }

    return valueAxis;
  }

  /**
   * Este método adiciona uma scrollbar básica no eixo X (coluna / linha)
   */
  public addBasicXYChartScrollbarX(): void {
    this.xYChart.scrollbarX = new this.am4core.Scrollbar();
    this.xYChart.scrollbarX.parent = this.xYChart.bottomAxesContainer;
    this.xYChart.scrollbarX.marginTop = 25;
    this.xYChart.scrollbarX.marginBottom = 25;

    this.xYChart.zoomOutButton.align = 'right';
    this.xYChart.zoomOutButton.valign = 'top';
  }

  /**
   * Este método adiciona uma legenda básica no gráfico (coluna / linha)
   */
  public addBasicXYChartLegend(): void {
    this.xYChart.legend = new this.am4charts.Legend();
    this.xYChart.legend.position = 'bottom';
    this.xYChart.legend.labels.template.fontSize = 10;
    this.xYChart.legend.maxHeight = 100;
    this.xYChart.legend.useDefaultMarker = true;
    this.xYChart.legend.paddingBottom = 12;
    this.xYChart.legend.fontWeight = 'bold';
    //this.xYChart.legend.markers.template.disabled = true;

    this.xYChart.legend.itemContainers._template.paddingTop = 0;
    this.xYChart.legend.itemContainers._template.paddingBottom = 0;

    const marker = this.xYChart.legend.markers.template.children.getIndex(0);
    if (marker) {
      marker.width = 20;
      marker.height = 20;
      marker.paddingTop = 2;
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = this.am4core.color('#ccc');
    }
  }

  /**
   * Este método adiciona uma scrollbar básica no eixo X ( Radar )
   */
  public addBasicRadarChartScrollbarX(): void {
    this.radarChart.scrollbarX = new this.am4core.Scrollbar();
    this.radarChart.scrollbarX.parent = this.radarChart.bottomAxesContainer;
    this.radarChart.scrollbarX.marginTop = 25;
    this.radarChart.scrollbarX.marginBottom = 25;

    this.radarChart.zoomOutButton.align = 'right';
    this.radarChart.zoomOutButton.valign = 'top';
  }

  public addBasicXYChartAxisRanges(
    valueAxis: am4charts.ValueAxis<any>,
    value: number,
    endValue: number,
    text: string,
    textColor: string,
    gridColor: string
  ): am4charts.ValueAxisDataItem {
    const axisRanges = valueAxis.axisRanges.create();
    axisRanges.value = value;
    axisRanges.endValue = endValue;
    axisRanges.label.text = this.translateService.instant(text);
    axisRanges.label.rotation = 90;
    axisRanges.label.horizontalCenter = 'middle';
    axisRanges.label.verticalCenter = 'middle';
    axisRanges.label.fill = this.am4core.color(textColor);
    axisRanges.axisFill.fill = this.am4core.color(gridColor);

    return axisRanges;
  }

  /**
   * Este método inicializa e configura as opções de exportação dos gráficos
   */
  public createExportGraph(): am4core.ExportMenu {
    const exportMenu = new am4core.ExportMenu();
    exportMenu.align = 'right';
    exportMenu.verticalAlign = 'bottom';
    exportMenu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Imagem',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'svg', label: 'SVG' },
              { type: 'pdf', label: 'PDF' },
              { type: 'print', label: 'PRINT' },
            ],
          },
          // {
          //   label: 'Dados',
          //   menu: [
          //     { type: 'json', label: 'JSON' },
          //     { type: 'xlsx', label: 'XLSX' },
          //     { type: 'pdfdata', label: 'PDF' },
          //   ],
          // },
        ],
      },
    ];

    return exportMenu;
  }

  public configureLanguage(): void {
    if (this.translateService.currentLang === 'pt') {
      if (this.xYChart) {
        this.xYChart.language.locale = am4lang_pt_BR;
      } else {
        this.radarChart.language.locale = am4lang_pt_BR;
      }
    } else {
      if (this.xYChart) {
        this.xYChart.language.locale = am4lang_en_US;
      } else {
        this.radarChart.language.locale = am4lang_en_US;
      }
    }
  }
}
