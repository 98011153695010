<div class="modal-container">
  <button class="btn-close" (click)="close()">
    <i class="las la-times"></i>
  </button>
  <h1 mat-dialog-title>
    <img src="./assets/icons/report/ico-fazenda.svg" />
    {{ data.nome }}
  </h1>
  <div mat-dialog-content>
    <ul class="farm-details-areas">
      <li class="item" *ngFor="let item of data.glebesInfos">
        <h4>
          {{ item.alias }}
        </h4>
        <div class="row">
          <div class="col-6">
            Cultura: {{ item.cropName }}<br />
            Variedade: {{ item.varieties }}<br />
            Área (ha): {{ item.area.toFixed(2) }}<br />
            Total talhões: {{ item.nFields }}<br />
            Total armadilhas: {{ item.nTraps }}<br />
          </div>
          <div class="col-6">
            <!-- COLOCAR VARIAVEL COM O NIVEL DE INFESTAÇÃO NA CLASSE / ESPERA-SE: HIGH / MEDIUM / MODERATE -->
            Média MAD: <span class="marker {{ getFtdLevel(item.ftdMean) }}"></span>
            {{ item.ftdMean.toFixed(2) }}<br />
            <!-- Infestastação: <span class="{{ item.infestation}}"></span> {{ item.infestation }}<br /> -->
            <button type="button" class="btn btn-primary" [mat-dialog-close]="item">
              Ver detalhes
            </button>
          </div>
        </div>
      </li>
    </ul>

    <hr />

    <div class="contato">
      <span>Dúvidas, críticas ou sugestões?</span>
      <button type="button" class="btn btn-primary" (click)="openLinkWhats()">
        <i class="lab la-whatsapp"></i> Entre em contato
      </button>
    </div>
  </div>
  <!-- <div mat-dialog-actions>
    <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
  </div> -->
</div>
