import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  TranslateDirective,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ToastrModule } from 'ngx-toastr';
import { Observable, from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { metaReducers, reducers } from '.';
import { AGEM_GOOGLE_MAPS, FIREBASE_CONFIG, environment } from '../environments/environment';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { COOKIE_CONFIG } from './constants/cookie';
import { toasterConfig } from './constants/toastr';
import { DisableControlDirective } from './directives/disable-control/disable-control.directive';
import { AccountService } from './services/account.service';
import { AuthGuard } from './services/auth-guard.service';
import { JwtInterceptorService } from './services/generic-http.service';
import { GenericReportFilesService } from './services/generic-report-files.service';
import { ImportKmzFormService } from './services/import-kmz-form.service';
import { LoginService } from './services/login.service';
import { MyToastrService } from './services/toastr.service';
import { TranslateTypes } from './services/translation.service';
import { BlockedApplicationModalComponent } from './view/components/blocked-application-modal/blocked-application-modal.component';
import { EvolutionChartComponent } from './view/components/charts/evolution-chart/evolution-chart.component';
import { InfestationEvolutionChartComponent } from './view/components/charts/infestation-evolution-chart/infestation-evolution-chart.component';
import { LevelExceededChartComponent } from './view/components/charts/level-exceeded-chart/level-exceeded-chart.component';
import { FarmDetailsModalComponent } from './view/report-fruit/farm-details-modal/farm-details-modal.component';
import { MadChartComponent } from './view/components/charts/mad-chart/mad-chart.component';
import { MediaMadChartComponent } from './view/components/charts/media-mad-chart/media-mad-chart.component';
import { WeatherChartComponent } from './view/components/charts/weather-chart/weather-chart.component';
import { ClimateChartComponent } from './view/components/charts/climate-chart/climate-chart.component';
import { SoilChartComponent } from './view/components/charts/soil-chart/soil-chart.component';
import { MovingAverageChartComponent } from './view/components/charts/moving-average-chart/moving-average-chart.component';
import { TotalBetweenFieldsChartComponent } from './view/components/charts/total-between-fields-chart/total-between-fields-chart.component';
import { EmptyStatesBoxComponent } from './view/components/empty-states-box/empty-states-box.component';
import { ImageGalleryComponent } from './view/components/image-gallery/image-gallery.component';
import { LoadingComponent } from './view/components/loading/loading.component';
import { GoogleMapsDrawMarkerComponent } from './view/components/maps/google-maps-draw-marker/google-maps-draw-marker.component';
import { GoogleMapsDrawPolygonComponent } from './view/components/maps/google-maps-draw-polygon/google-maps-draw-polygon.component';
import { GoogleMapsViewComponent } from './view/components/maps/google-maps-view/google-maps-view.component';
import { MapsGeneralMonitoringComponent } from './view/components/maps/maps-general-monitoring/maps-general-monitoring.component';
import { ProgressBarComponent } from './view/components/progress-bar/progress-bar.component';
import { RemoveModalComponent } from './view/components/remove-modal/remove-modal.component';
import { WeatherForecastComponent } from './view/components/weather-forecast/weather-forecast.component';
import { OccurrenceSettingsComponent } from './view/configuration/occurrence-settings/occurrence-settings.component';
import { OccurrenceSettingsEffects } from './view/configuration/occurrence-settings/occurrence-settings.effects';
import { SystemSettingsComponent } from './view/configuration/system-settings/system-settings.component';
import { SystemSettingsEffects } from './view/configuration/system-settings/system-settings.effects';
import { FarmFormComponent } from './view/farm/farm-form/farm-form.component';
import { FarmComponent } from './view/farm/farm.component';
import { FarmEffects } from './view/farm/farm.effects';
import { FieldFormComponent } from './view/field/field-form/field-form.component';
import { FieldFormEffects } from './view/field/field-form/field-form.effects';
import { FieldComponent } from './view/field/field.component';
import { FieldEffects } from './view/field/field.effects';
import { ImportFieldFormComponent } from './view/field/import-field-form/import-field-form.component';
import { ImportFieldFormEffects } from './view/field/import-field-form/import-field-form.effects';
import { ReapFormComponent } from './view/field/reap-form/reap-form.component';
import { ReapFormEffects } from './view/field/reap-form/reap-form.effects';
import { GlebeFormComponent } from './view/glebe/glebe-form/glebe-form.component';
import { GlebeFormEffects } from './view/glebe/glebe-form/glebe-form.effects';
import { GlebeComponent } from './view/glebe/glebe.component';
import { GlebeEffects } from './view/glebe/glebe.effects';
import { ImportGlebeFormComponent } from './view/glebe/import-glebe-form/import-glebe-form.component';
import { ImportGlebeFormEffects } from './view/glebe/import-glebe-form/import-glebe-form.effects';
import { EvolutionModalComponent } from './view/gm-tabs/charts/evolution-modal/evolution-modal.component';
import { InfestationEvolutionModalComponent } from './view/gm-tabs/charts/infestation-evolution-modal/infestation-evolution-modal.component';
import { LevelExceededModalComponent } from './view/gm-tabs/charts/level-exceeded-modal/level-exceeded-modal.component';
import { ListChartComponent } from './view/gm-tabs/charts/list-chart/list-chart.component';
import { MadModalComponent } from './view/gm-tabs/charts/mad-modal/mad-modal.component';
import { ClimateModalComponent } from './view/gm-tabs/charts/climate-modal/climate-modal.component';
import { SoilModalComponent } from './view/gm-tabs/charts/soil-modal/soil-modal.component';
import { MovingAverageModalComponent } from './view/gm-tabs/charts/moving-average-modal/moving-average-modal.component';
import { TotalBetweenFieldsModalComponent } from './view/gm-tabs/charts/total-between-fields-modal/total-between-fields-modal.component';
import { GeneralInfoComponent } from './view/gm-tabs/general-info/general-info.component';
import { GmTabsComponent } from './view/gm-tabs/gm-tabs.component';
import { GmTabsEffects } from './view/gm-tabs/gm-tabs.effects';
import { GmFilterComponent } from './view/gm/gm-filter/gm-filter.component';
import { GmComponent } from './view/gm/gm.component';
import { GmEffects } from './view/gm/gm.effects';
import { HarvestFormComponent } from './view/harvest/harvest-form/harvest-form.component';
import { HarvestFormEffects } from './view/harvest/harvest-form/harvest-form.effects';
import { HarvestComponent } from './view/harvest/harvest.component';
import { HarvestEffects } from './view/harvest/harvest.effects';
import { AccountFormComponent } from './view/header/account-form/account-form.component';
import { HeaderComponent } from './view/header/header.component';
import { HeaderEffects } from './view/header/header.effects';
import { NotificationComponent } from './view/header/notification/notification.component';
import { SelectCustomerComponent } from './view/header/select-customer/select-customer.component';
import { SelectFarmAndCropComponent } from './view/header/select-farm-and-crop/select-farm-and-crop.component';
import { UserHeaderComponent } from './view/header/user-header/user-header.component';
import { HomeComponent } from './view/home/home.component';
import { HomeEffects } from './view/home/home.effects';
import { DefineFieldFormComponent } from './view/import-kmz/define-field-form/define-field-form.component';
import { DefineFieldFormEffects } from './view/import-kmz/define-field-form/define-field-form.effects';
import { GoogleMapsImportKmzComponent } from './view/import-kmz/google-maps-import-kmz/google-maps-import-kmz.component';
import { ImportKmzFormComponent } from './view/import-kmz/import-kmz-form/import-kmz-form.component';
import { ImportKmzFormEffects } from './view/import-kmz/import-kmz-form/import-kmz-form.effects';
import { ImportKmzComponent } from './view/import-kmz/import-kmz.component';
import { ImportKmzEffects } from './view/import-kmz/import-kmz.effects';
import { LoginComponent } from './view/login/login.component';
import { LoginEffects } from './view/login/login.effects';
import { MenuComponent } from './view/menu/menu.component';
import { RouteOptimizationComponent } from './view/route-optimization/route-optimization.component';
import { RecoverPasswordComponent } from './view/recover-password/recover-password.component';
import { ReportComponent } from './view/report/report.component';
import { ReportFruitComponent } from './view/report-fruit/report-fruit.component';
import { ReportEffects } from './view/report/report.effects';
import { ReportFruitEffects } from './view/report-fruit/report-fruit.effects';
import { TrapReportFormComponent } from './view/trap-report/trap-report-form/trap-report-form.component';
import { TrapReportFormEffects } from './view/trap-report/trap-report-form/trap-report-form.effects';
import { TrapReportComponent } from './view/trap-report/trap-report.component';
import { TrapReportEffects } from './view/trap-report/trap-report.effects';
import { ImportTrapFormComponent } from './view/trap/import-trap-form/import-trap-form.component';
import { ImportTrapFormEffects } from './view/trap/import-trap-form/import-trap-form.effects';
import { TrapFormComponent } from './view/trap/trap-form/trap-form.component';
import { TrapComponent } from './view/trap/trap.component';
import { TrapEffects } from './view/trap/trap.effects';
import { UpdateLinerModalComponent } from './view/trap/update-liner-modal/update-liner-modal.component';
import { UpdatePasswordComponent } from './view/update-password/update-password.component';
import { UserFormComponent } from './view/user/user-form/user-form.component';
import { UserComponent } from './view/user/user.component';
import { UserEffects } from './view/user/user.effects';
import { CustomPaginator } from './view/utils/custom-table/custom-paginator';
import { UsefulService } from './view/utils/useful-methods/useful.service';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';

import 'rxjs/add/operator/first';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { LabelPipe } from './pipes/label.pipe';
import { FormService } from './services/form.service';
import { AccumulatedChartComponent } from './view/components/charts/accumulated-chart/accumulated-chart.component';
import { InfoModalComponent } from './view/components/info-modal/info-modal.component';
import { TooltipComponent } from './view/components/tooltip/tooltip.component';
import { CreateCustomerComponent } from './view/customer/create-customer/create-customer.component';
import { CreateCustomerEffects } from './view/customer/create-customer/create-customer.effects';
import { AccumulatedModalComponent } from './view/gm-tabs/charts/accumulated-modal/accumulated-modal.component';
import { InstructionModalComponent } from './view/report/instruction-modal/instruction-modal.component';
import { InstructionModalFruitComponent } from './view/report-fruit/instruction-modal-fruit/instruction-modal-fruit.component';
import { WhiteSpaceValidatorDirective } from './view/utils/custom-validators/white-space-validator.directive';
import { PageNotFoundComponent } from './view/page-not-found/page-not-found.component';
import { UpdateUserComponent } from './view/update-user/update-user.component';
import { GenericDialogComponent } from './view/components/generic-dialog/generic-dialog.component';
import { UpdateUserEffects } from './view/update-user/update-user.effects';
import { EffectsServices } from './services/effects.service';
import { CalculateService } from './services/calculate.service';
import { BasicMapComponent } from './view/components/maps/basic-map/basic-map.component';
import { RouteOptimizationEffects } from './view/route-optimization/route-optimization.effects';
import { FarmsRouteOptimizationEffects } from './view/route-optimization/store/farms/fams-route-optimization.effects';
import { CustomersRouteOptimizationEffects } from './view/route-optimization/store/customers/customers-route-optimization.effects';

export class WebpackTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    HomeComponent,
    UserComponent,
    UserHeaderComponent,
    UserFormComponent,
    FieldComponent,
    FieldFormComponent,
    AccountFormComponent,
    RemoveModalComponent,
    UpdatePasswordComponent,
    GlebeComponent,
    GlebeFormComponent,
    BasicMapComponent,
    GoogleMapsViewComponent,
    GoogleMapsDrawPolygonComponent,
    GoogleMapsDrawMarkerComponent,
    MapsGeneralMonitoringComponent,
    FarmComponent,
    FarmFormComponent,
    GmComponent,
    RouteOptimizationComponent,
    TrapComponent,
    TrapFormComponent,
    GenericDialogComponent,
    GmFilterComponent,
    GmTabsComponent,
    MenuComponent,
    ImportKmzComponent,
    ImportKmzFormComponent,
    BlockedApplicationModalComponent,
    HarvestComponent,
    HarvestFormComponent,
    DisableControlDirective,
    ReapFormComponent,
    EvolutionModalComponent,
    AccumulatedModalComponent,
    LevelExceededModalComponent,
    MadModalComponent,
    MediaMadChartComponent,
    ClimateModalComponent,
    SoilModalComponent,
    ImageGalleryComponent,
    EmptyStatesBoxComponent,
    LoadingComponent,
    SelectFarmAndCropComponent,
    SelectCustomerComponent,
    NotificationComponent,
    TrapReportComponent,
    TrapReportFormComponent,
    WeatherForecastComponent,
    ProgressBarComponent,
    InfestationEvolutionModalComponent,
    OccurrenceSettingsComponent,
    TotalBetweenFieldsModalComponent,
    GeneralInfoComponent,
    ListChartComponent,
    SystemSettingsComponent,
    ImportGlebeFormComponent,
    ImportFieldFormComponent,
    ImportTrapFormComponent,
    GoogleMapsImportKmzComponent,
    DefineFieldFormComponent,
    MovingAverageModalComponent,
    RecoverPasswordComponent,
    ReportComponent,
    ReportFruitComponent,
    EvolutionChartComponent,
    AccumulatedChartComponent,
    InfestationEvolutionChartComponent,
    LevelExceededChartComponent,
    MovingAverageChartComponent,
    MadChartComponent,
    ClimateChartComponent,
    SoilChartComponent,
    TotalBetweenFieldsChartComponent,
    WeatherChartComponent,
    InfoModalComponent,
    UpdateLinerModalComponent,
    WhiteSpaceValidatorDirective,
    InstructionModalComponent,
    InstructionModalFruitComponent,
    FarmDetailsModalComponent,
    LabelPipe,
    TooltipComponent,
    TooltipDirective,
    CreateCustomerComponent,
    PageNotFoundComponent,
    UpdateUserComponent,
    GenericDialogComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ngx-cookieconsent-demo-id' }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AgmSnazzyInfoWindowModule,
    CdkTreeModule,
    DragDropModule,
    ScrollingModule,
    FlexLayoutModule,
    AgmCoreModule.forRoot(AGEM_GOOGLE_MAPS),
    NgxMaskModule.forRoot(),

    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,

    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatStepperModule,
    MatDividerModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTreeModule,
    MaterialFileInputModule,

    ToastrModule.forRoot(toasterConfig),
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireModule.initializeApp(FIREBASE_CONFIG),
    NgcCookieConsentModule.forRoot(COOKIE_CONFIG),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([
      LoginEffects,
      HeaderEffects,
      FarmEffects,
      RouteOptimizationEffects,
      FarmsRouteOptimizationEffects,
      CustomersRouteOptimizationEffects,
      GmEffects,
      GlebeEffects,
      GlebeFormEffects,
      UserEffects,
      FieldEffects,
      FieldFormEffects,
      TrapEffects,
      TrapReportEffects,
      TrapReportFormEffects,
      GmTabsEffects,
      ImportKmzEffects,
      ImportKmzFormEffects,
      HomeEffects,
      HarvestEffects,
      HarvestFormEffects,
      ReapFormEffects,
      OccurrenceSettingsEffects,
      SystemSettingsEffects,
      ImportGlebeFormEffects,
      ImportFieldFormEffects,
      ImportTrapFormEffects,
      DefineFieldFormEffects,
      UpdateUserEffects,
      CreateCustomerEffects,
      ReportEffects,
      ReportFruitEffects,
    ]),
  ],
  providers: [
    AuthGuard,
    TranslateTypes,
    TranslateService,
    TranslatePipe,
    TranslateDirective,
    LoginService,
    AccountService,
    CalculateService,
    FormService,
    GenericReportFilesService,
    ImportKmzFormService,
    MyToastrService,
    PercentPipe,
    DecimalPipe,
    UsefulService,
    EffectsServices,
    LabelPipe,
    EffectsServices,
    { provide: MatPaginatorIntl, useClass: CustomPaginator },
    { provide: MatDialogRef, useValue: {} },
    // { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulateAuth ? ['http://localhost:9099'] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.emulateFirestore ? ['localhost', 8081] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulateFunctions ? ['localhost', 5001] : undefined,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
