import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { PageNotFoundComponent } from './view/page-not-found/page-not-found.component';
import { OccurrenceSettingsComponent } from './view/configuration/occurrence-settings/occurrence-settings.component';
import { SystemSettingsComponent } from './view/configuration/system-settings/system-settings.component';
import { CreateCustomerComponent } from './view/customer/create-customer/create-customer.component';
import { FarmComponent } from './view/farm/farm.component';
import { FieldComponent } from './view/field/field.component';
import { GlebeComponent } from './view/glebe/glebe.component';
import { GmComponent } from './view/gm/gm.component';
import { HarvestComponent } from './view/harvest/harvest.component';
import { HomeComponent } from './view/home/home.component';
import { ImportKmzComponent } from './view/import-kmz/import-kmz.component';
import { LoginComponent } from './view/login/login.component';
import { RecoverPasswordComponent } from './view/recover-password/recover-password.component';
import { ReportComponent } from './view/report/report.component';
import { ReportFruitComponent } from './view/report-fruit/report-fruit.component';
import { TrapReportComponent } from './view/trap-report/trap-report.component';
import { TrapComponent } from './view/trap/trap.component';
import { UpdatePasswordComponent } from './view/update-password/update-password.component';
import { UserComponent } from './view/user/user.component';
import { UpdateUserComponent } from './view/update-user/update-user.component';
import { RouteOptimizationComponent } from './view/route-optimization/route-optimization.component';

export const routes = [
  {
    path: '',
    redirectTo: 'home/gm',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'gm', component: GmComponent, canActivate: [AuthGuard] },
      { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
      { path: 'field', component: FieldComponent, canActivate: [AuthGuard] },
      { path: 'glebe', component: GlebeComponent, canActivate: [AuthGuard] },
      {
        path: 'harvest',
        component: HarvestComponent,
        canActivate: [AuthGuard],
      },
      { path: 'farm', component: FarmComponent, canActivate: [AuthGuard] },
      { path: 'trap', component: TrapComponent, canActivate: [AuthGuard] },
      {
        path: 'configuration/occurrence',
        component: OccurrenceSettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'configuration/system',
        component: SystemSettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'configuration/import-kmz',
        component: ImportKmzComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trapReport',
        component: TrapReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'routeOptimization',
        component: RouteOptimizationComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createCustomer',
    component: CreateCustomerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'updateUser',
    component: UpdateUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recoverPassword',
    component: RecoverPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'updatePassword',
    component: UpdatePasswordComponent,
  },
  {
    path: 'report',
    component: ReportComponent,
  },
  {
    path: 'reportFruit',
    component: ReportFruitComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
