import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Infestation } from '@tarvos-ag/tarvos-firestore-models/src/enums/Infestation';
import { Glebe } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { FieldsByGlebe, GlebeInfos } from 'src/app/interfaces/Report';

@Component({
  selector: 'app-farm-details-modal',
  templateUrl: './farm-details-modal.component.html',
  styleUrls: ['./farm-details-modal.component.scss'],
})
export class FarmDetailsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<FarmDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fone: number;
      glebe: Array<FieldsByGlebe>;
      nome: string;
      glebesInfos: Array<GlebeInfos>;
    }
  ) {}

  public openLinkWhats(): void {
    window.open(`${this.data.fone}`, '_blank');
  }

  close(): void {
    this.dialogRef.close();
  }

  getGlebe(glebeId: string): Glebe {
    return this.data.glebe.filter((g) => g.glebe.id == glebeId)[0].glebe;
  }

  public getFtdLevel(ftdMean?: number): Infestation | null {
    if (!ftdMean) return null;
    else if (ftdMean >= 1) return Infestation.HIGH;
    else if (ftdMean >= 0.5) return Infestation.MEDIUM;
    else return Infestation.MODERATE;
  }
}
