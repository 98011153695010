<app-progress-bar [isOpen]="state.loadingEvolutionChart"></app-progress-bar>

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>
    {{ trans.title.dailyCaptures | translate }}
    {{ state.evolutionChart ? '(' + state.evolutionChart?.name + ')' : '' }}
    <button
      class="custom-button-help"
      mat-mini-fab
      color="primary"
      matTooltipClass="custom-tooltip-help"
      [matTooltip]="trans.text.helpEvolutionChart['TRAP'] | translate"
    >
      ?
    </button>
  </h2>

  <button
    mat-icon-button
    autofocus="false"
    class="custom-button-close"
    (click)="closeModal()"
    tabindex="-1"
  >
    <i class="las la-times"></i>
  </button>
</div>

<mat-dialog-content style="height: 75vh; overflow: hidden">
  <app-evolution-chart
    *ngIf="state.evolutionChart !== null"
    [data]="state.evolutionChart"
    [isFruit]="false"
  ></app-evolution-chart>
  <app-empty-states-box
    *ngIf="state.loadingEvolutionChart <= 0 && state.evolutionChart === null"
    [iconSize]="'62px'"
    [messageSize]="'22px'"
    [messagePadding]="'45px 20px'"
    [icon]="'la-chart-line'"
    [message]="trans.text.chartNotFound"
  ></app-empty-states-box>
</mat-dialog-content>
