import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-basic-map',
  templateUrl: './basic-map.component.html',
  styleUrls: ['./basic-map.component.scss'],
})
export class BasicMapComponent implements OnInit, OnChanges {
  @Input() height: string = '500px';
  @Input() width: string = '100%';
  @Input() latitude!: number;
  @Input() longitude!: number;
  @Input() zoom: number = 4;
  @Input() markers: Array<{
    lat: number;
    lng: number;
    label?: string;
    iconUrl?: string;
  }> = [];
  @Input() routeSegments: Array<Array<{ lat: number; lng: number }>> = [];
  @Input() durations: number[] = [];

  colors = ['red', 'blue', 'green', 'orange', 'purple', 'yellow', 'cyan', 'magenta'];

  durationMarkers: Array<{
    lat: number;
    lng: number;
    label: string;
  }> = [];

  constructor() {}

  ngOnInit(): void {
    console.log(
      'Mapa iniciado com latitude:',
      this.latitude,
      'longitude:',
      this.longitude,
      'zoom:',
      this.zoom
    );
    this.generateDurationMarkers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['markers']) {
    //   console.log('Markers updated in BasicMapComponent:', this.markers);
    // }
    if (changes['routeSegments'] || changes['durations']) {
      // console.log(
      //   'Route segments or durations updated in BasicMapComponent:',
      //   this.routeSegments,
      //   this.durations
      // );
      this.generateDurationMarkers();
    }
  }

  getColor(index: number): string {
    return this.colors[index % this.colors.length];
  }

  /**
   * Gera marcadores de duração no meio de cada polilinha
   */
  generateDurationMarkers(): void {
    this.durationMarkers = [];

    if (
      this.routeSegments &&
      this.durations &&
      this.routeSegments.length === this.durations.length
    ) {
      this.routeSegments.forEach((segment, index) => {
        if (segment.length === 0) return;

        const midIndex = Math.floor(segment.length / 2);
        const midpoint = segment[midIndex];

        const durationMinutes = Math.floor(this.durations[index] / 60);
        const durationSeconds = this.durations[index] % 60;
        const formattedDuration = `${durationMinutes}m ${durationSeconds}s`;

        this.durationMarkers.push({
          lat: midpoint.lat,
          lng: midpoint.lng,
          label: formattedDuration,
        });
      });
    }
  }

  /**
   * Método trackBy para rotas (segmentos)
   * @param index Índice do item
   * @param item Item atual
   * @returns Índice (único)
   */
  trackByIndex(index: number, item: any): number {
    return index;
  }

  /**
   * Método trackBy para marcadores
   * @param index Índice do item
   * @param marker Marcador atual
   * @returns Combinação única de latitude e longitude
   */
  trackByMarker(index: number, marker: any): string {
    return `${marker.lat}-${marker.lng}`;
  }
}
